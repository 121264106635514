import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'fullpage.js/vendors/scrolloverflow';
import '@/assets/overall.scss';
import VueFullPage from 'vue-fullpage.js';// 滚屏
import VueKinesis from 'vue-kinesis';// 视差
import VueAwesomeSwiper from 'vue-awesome-swiper';
import MetaInfo from 'vue-meta-info';
import 'animate.css';

Vue.use(VueFullPage);// 滚屏
Vue.use(VueKinesis);// 视差
Vue.use(VueAwesomeSwiper);// 轮播
Vue.config.productionTip = false;
Vue.use(MetaInfo);

Vue.prototype.$isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};


new Vue({
    router,
    mounted() {
        document.dispatchEvent(new Event('render-event'));
    },
    render: h => h(App)
}).$mount('#app');
