<template>
    <div>

        <div class="pc-display">
            <page-menu></page-menu>
        </div>
        <div class="phone-display">
            <Menu></Menu>
            <PhoneMenu></PhoneMenu>
        </div>
        <div class="banner-box">
            <div class="overall-box">
                <div class="title-box">
                    <div class="en animate__animated animate__fadeInDown">
                        Exclusive payment channel
                    </div>
                    <div class="animate__animated animate__fadeInDown">
                        <b>
                            极速收款方式<br />
                            便捷安全的解决收款难题
                        </b>
                    </div>
                    <ul class="animate__animated animate__fadeInDown">
                        <li>支持操作资金渠道，不参与资金结算<span class="pc-display">，保障商户资金安全</span></li>
                        <li>资金渠道可实时查询余额，方便管理</li>
                    </ul>
                    <img src="@/assets/images/page/banner-product.png" class="animate__animated animate__fadeInUp">
                </div>
            </div>
        </div>
        <div class="overall-box page-content-box">
            <div class="title-box">
                <div class="animate-title">
                    <b>产品特色</b>
                    <div class="en">Product Characteristics</div>
                </div>
                <div class="wire animate-subhead"></div>
            </div>
            <ul class="feature-box">
                <li class="animate-list" style="animation-delay: 0.2s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png">
                        <i class="iconfont">&#xe727;</i>
                    </div>
                    <div class="title">高 效</div>
                    <div class="txt">
                        提供平台在线收款、API收款等多种对接方式，<br class="pc-display" />
                        让接入更高效。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 0.4s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png">
                        <i class="iconfont">&#xe620;</i>
                    </div>
                    <div class="title">便 捷</div>
                    <div class="txt">
                        平台功能完善，使用便捷，<br class="pc-display" />
                        支持订单一键导出，方便统计对账。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 0.6s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png">
                        <i class="iconfont">&#xe636;</i>
                    </div>
                    <div class="title">安 全</div>
                    <div class="txt">
                        支付功能全链路加密，订单过程可追溯，<br class="pc-display" />
                        让客户的资金安全得到保障。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 0.8s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png">
                        <i class="iconfont">&#xeaa0;</i>
                    </div>
                    <div class="title">灵 活</div>
                    <div class="txt">
                        服务适配各行业，<br class="pc-display" />
                        提供灵活的产品对接方式。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 1s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png">
                        <i class="iconfont">&#xe651;</i>
                    </div>
                    <div class="title">定 制</div>
                    <div class="txt">
                        根据客户实际业务场景，<br class="pc-display" />
                        支持个性化方案的定制。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 1.2s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png">
                        <i class="iconfont">&#xe817;</i>
                    </div>
                    <div class="title">可 靠</div>
                    <div class="txt">
                        7*24小时专属客服，及时响应。<br class="pc-display" />
                        为您提供可靠的服务保证。
                    </div>
                </li>
            </ul>
            <div class="title-box">
                <div class="animate-title">
                    <b>产品功能</b>
                    <div class="en">product function</div>
                </div>
                <div class="wire animate-subhead"></div>
            </div>
            <div class="function-box animate-cutButton">
                <img src="@/assets/images/page/function02_t.png" class="top">
                <img src="@/assets/images/page/function02_b.png">
            </div>
            <div class="title-box">
                <div class="animate-title">
                    <b>产品功能</b>
                    <div class="en">product function</div>
                </div>
                <div class="wire animate-subhead"></div>
            </div>
            <ul class="product-img-box">
                <li class="animate-list" style="animation-delay: 0.2s">
                    <div class="img-box">
                        <img src="@/assets/images/page/coll01.png">
                        <img src="@/assets/images/page/shadow.png" class="shadow">
                    </div>
                    <div class="title-box">
                        <div>订单管理</div>
                        <div class="txt">统计时间范围可进行调整</div>
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 0.4s">
                    <div class="img-box">
                        <img src="@/assets/images/page/coll02.png">
                        <img src="@/assets/images/page/shadow.png" class="shadow">
                    </div>
                    <div class="title-box">
                        <div>数据统计</div>
                        <div class="txt">设置多账号余额预警，低于预警值发送预警通知，
                            通知人可设置多人</div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="footer-box">
            <div class="pc-display">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>


<script>
import Menu from '@/components/Menu.vue';// 菜单
import PageMenu from '@/components/PageMenu.vue';// 菜单
import PhoneMenu from '@/components/PhoneMenu.vue';// 菜单
import Banner from '@/components/Banner.vue';// 轮播
import Footer from '@/components/Footer.vue';// 底部
export default {
    name: 'ProductView',
    components: { Menu, PageMenu, Banner, Footer, PhoneMenu },
    metaInfo: {
        meta: [{                 // set meta
            name: '微信支付宝收款,二维码收款,快捷支付,微信支付宝支付,商户分账,账户产品,代收,银行卡收单,微信支付宝收单,二清账户,支付解决方案',
            content: '微信收款,支付宝收款,二维码收款,快捷支付,微信支付,支付宝支付，商户分账,账户产品,代收,银行卡收单,微信收单,支付宝收单,二清账户,支付解决方案'
        }]
    },
    data() {
        return {
        };
    },
    mounted() {
        // 定义一个函数，用于添加动画类
        function addAnimationClass(elements, animationClass) {
            const windowHeight = window.innerHeight; // 获取窗口高度
            elements.forEach(function (div) {
                const rect = div.getBoundingClientRect(); // 获取div相对于视窗的位置
                // 检查div是否进入视窗
                if (rect.top <= windowHeight && rect.bottom >= 0) {
                    div.classList.add('animate__animated', animationClass);
                }
            });
        }
        window.addEventListener('scroll', function () {
            // 获取需要添加动画的元素
            const subhead = document.querySelectorAll('.animate-subhead');// 副标题
            const title = document.querySelectorAll('.animate-title');// 标题
            const cutButton = document.querySelectorAll('.animate-cutButton');// 切换按钮
            const caseTypeL = document.querySelectorAll('.animate-caseType-left');// 切换按钮
            const caseTypeR = document.querySelectorAll('.animate-caseType-right');// 切换按钮
            const list = document.querySelectorAll('.animate-list');// 切换按钮

            // 添加动画类
            addAnimationClass(subhead, 'animate__zoomIn');
            addAnimationClass(title, 'animate__slideInDown');
            addAnimationClass(cutButton, 'animate__fadeInUp');
            addAnimationClass(caseTypeL, 'animate__fadeInLeft');
            addAnimationClass(caseTypeR, 'animate__fadeInRight');
            addAnimationClass(list, 'animate__fadeIn');
        });
    },
    methods: {
        product() {
            this.$router.push('/product');
        },
        productCollect() {
            this.$router.push('/productCollect');
        }
    }
};
</script>
<style scoped lang="scss">
.page-big-title {
  padding: 135px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-left {
    margin-left: 69px;
    color: #333;
    font-size: 18px;
    text-align: left;

    b {
      font-size: 60px;
    }
  }

  .content-right {
    width: 560px;
    margin-right: 69px;
    color: #666;
    font-size: 15px;
    line-height: 30px;
    text-align: left;
  }
}

.banner-box {
  width: 100%;
  height: 600px;
  background: #edfff6 url(@/assets/images/page/banner-bg.png) no-repeat left bottom;
  // background: linear-gradient(to left, #32c9ff, #1b7af9);
  margin-bottom: 89px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  img {
    height: 600px;
    position: absolute;
    left: 50%;
    top: 90px;
    z-index: 10;
  }

  .title-box {
    width: 610px;
    margin-left: 70px;
    color: #333;
    text-align: left;
    padding-top: 60px;


    b {
      font-size: 50px;
    }

    .en {
      font-size: 15px;
      color: #666;
      margin-bottom: 15px;
    }

    ul {
      font-size: 14px;
      color: #666;
      line-height: 37px;
      margin-top: 30px;
    }

    li {
      background: url(@/assets/images/li-w.png) no-repeat left center;
      background-size: 17px;
      text-indent: 20px;
    }
  }
}

ul.feature-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 80px;

  li {
    width: 30%;
    height: 275px;
    margin: 0 1% 33px 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      opacity: 1;
      position: absolute;
      z-index: 1;
      left: -100%;
    }
    @keyframes slideAnimation {
      0% {
        left: -100%;
      }
      100% {
        left: 200%;
      }
    }
    &:hover {
      cursor: pointer;
      img {
        left: 200%;
        animation: slideAnimation 1.5s linear infinite;
      }
    }
  }
  .icon-box {
    width: 110px;
    height: 110px;
    border-radius: 100%;
    color: #fff;
    line-height: 110px;
    text-align: center;
    box-shadow: 0px 23px 30px #ffddcc;
    background: linear-gradient(45deg, #f99b4e, #ffae6a);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    i {
      font-size: 55px;
    }
  }

  li:nth-of-type(2) {
    .icon-box {
      box-shadow: 0px 23px 30px #d9d2ff;
      background: linear-gradient(45deg, #6b4eff, #9571ff);
    }
  }

  li:nth-of-type(3) {
    .icon-box {
      box-shadow: 0px 23px 30px #c3e4ff;
      background: linear-gradient(45deg, #017ae1, #2ba2ff);
    }
  }

  li:nth-of-type(4) {
    .icon-box {
      box-shadow: 0px 23px 30px #c6f3d6;
      background: linear-gradient(45deg, #1ad05b, #84de56);
    }
  }

  li:nth-of-type(5) {
    .icon-box {
      box-shadow: 0px 23px 30px #f8e5bd;
      background: linear-gradient(45deg, #f6d573, #fae089);
    }
  }

  li:nth-of-type(6) {
    .icon-box {
      box-shadow: 0px 23px 30px #edbbe7;
      background: linear-gradient(45deg, #db74d5, #f889ad);
    }
  }

  .title {
    font-size: 20px;
    color: #333;
    line-height: 85px;
  }

  .txt {
    font-size: 14px;
    color: #666;
    line-height: 26px;
  }
}

.function-box {
  width: 100%;
  margin-bottom: 120px;
  position: relative;

  img {
    width: 100%;
  }

  img.top {
    position: absolute;
    top: 0;
  }
}

ul.product-img-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  li {
    width: 30%;
    max-width: 438px;

    .img-box {
      width: calc(100% - 16px);
      border: 8px #f0f0f0 solid;
      border-radius: 5px;
      position: relative;
    }

    img {
      width: 100%;
    }

    img.shadow {
      height: 33px;
      position: absolute;
      left: 0;
      bottom: -41px
    }

    .title-box {
      width: 93%;
      margin: auto;
      margin-top: 33px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      font-size: 15px;
      color: #666;
      line-height: 30px;
    }

    .txt {
      width: 70%;
      font-size: 14px;
      color: #999;
      text-align: left;
      line-height: 24px;
    }
  }
}

.title-button {
  border: #ebebeb 1px scroll;
  line-height: vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  position: fixed;
  top: 50vh;
  right: 1%;
  font-size: 0.8rem;
  z-index: 1000;

  .button {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px #bcc6d6 dashed;
    background: #fff;
    border-radius: 100%;
    color: #666;
    cursor: pointer;
    margin-bottom: 1vh;
    box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.1);
  }

  .button:hover,
  .pitch {
    background: #34cd8b;
    border: 1px #34cd8b solid;
    color: #fff;
  }
}

/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 900px) {
  $title-b: 1.1rem;
  $title: 1rem;
  $txt: 0.9rem;
  $txt-line: 0.9rem/1.2rem Arial;

  .page-big-title {
    width: 95%;
    margin: auto;
    padding: 8vh 0;
    padding-bottom: 0vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .content-left {
      width: 100%;
      margin: 0;
      font: 1rem/2.5rem Arial;

      b {
        font-size: 2rem;
      }
    }

    .content-right {
      width: 100%;
      margin-top: 3vh;
      font: 1rem/1.7rem Arial;
    }
  }

  .banner-box {
    background-size: 80%;
    margin-bottom: 4vh;
    height: auto;
    padding: 4vh 0;

    img {
      display: none;
    }

    .title-box {
      width: 90%;
      margin: auto;
      padding-top: 2vh;

      b {
        font-size: $title-b;
      }

      ul {
        margin-top: 2vh;
      }

      .en {
        margin-bottom: 0.5vh;
      }

      li {
        background-size: 13px;
        text-indent: 18px;
      }

      .en,
      li {
        font: 0.9rem/1.5rem Arial;
      }

    }
  }

  .page-content-box {

    padding: 6vh 0;

    .title-box {
      margin-bottom: 5vh;
    }

    b {
      font-size: $title-b;
    }

    .en {
      line-height: 3vh;
    }

    .wire {
      margin-top: 2vh;
      height: 1px;
      width: 15px;
    }
  }

  ul.feature-box {
    li {
      width: 44%;
      height: auto;
      margin: 0 3% 7vh 3%;

      .txt {
        font: $txt-line;
      }

      .title {
        line-height: 5vh;
        margin-top: 1vh;
        font-size: $title;
      }

      .txt {
        font: $txt-line;
      }
    }

    .icon-box {
      width: 60px;
      height: 60px;
      line-height: 60px;

      i {
        font-size: 2rem;
      }
    }
  }

  .function-box {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
    }
  }

  ul.product-img-box {
    flex-direction: column;

    li {
      width: 90%;
      margin: auto;
      margin-bottom: 5.5vh;

      .title-box {
        font-size: $title;
      }

      .txt {
        font: $txt-line;
      }
    }
  }
}
</style>
