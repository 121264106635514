<template>
    <div class="right-bg-img">
        <div class="page-bg">
            <div class="pc-display">
                <page-menu></page-menu>
            </div>

            <div class="phone-display">
                <Menu></Menu>
                <PhoneMenu></PhoneMenu>
            </div>

            <div class="overall-box">
                <div class="page-big-title">
                    <div class="content-left">
                        <div class="animate__animated animate__fadeInDown">
                            适配不同平台的不同的支付流程
                        </div>
                        <div class="animate__animated animate__fadeInDown">
                            <b>行业案例</b>
                        </div>
                        <div class="button-div animate__animated animate__fadeInUp">
                            <div
                                class="button"
                                :class="{ hover: caseShow.agileVisible }"
                                @click="classShow"
                            >
                                灵工平台
                            </div>
                            <div
                                class="button"
                                :class="{ hover: caseShow.payVisible }"
                                @click="classShow"
                            >
                                代付平台
                            </div>
                        </div>
                    </div>
                    <div class="content-right animate__animated animate__fadeInRight">
                        快速响应客户的需求变化，为行业客户提供先进、可靠、安全、高质量、易扩展的应用定制解决方案、产品解决方案以及相关的平台服务。
                    </div>
                </div>
            </div>

            <div
                v-show="caseShow.agileVisible"
                ref="fadePanelRef"
                class="content-box overall-box"
            >
                <ul data-name="box1" class="transform-panel scheme-box">
                    <li>
                        <div class="padding-box">
                            <div class="title-box">
                                <div class="broder animate-subhead"></div>
                                <div class="animate-title">
                                    <b>灵活用工佣金结算解决方案</b>
                                    <div class="en">
                                        Flexible payroll tax settlement solutions
                                    </div>
                                </div>
                            </div>
                            <img
                                src="@/assets/images/flow/agile.svg"
                                style="width: 100%; margin: 50px 0"
                                class="animate-cutButton"
                            />
                            <div class="content-txt animate-title">
                                福象灵工获税务机构授权，合规处理全流程涉税事宜，为用工企业和自由职业者谋求税收政策红利，打造更经济、灵活、高效的用工生态。
                            </div>
                        </div>
                    </li>
                </ul>

                <ul data-name="box2" class="case-box case01">
                    <li class="animate-list" style="animation-delay: 0.2s">
                        <div class="img-box"><img src="../assets/images/hy/sc.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>生产制造</b>
                                <div class="content-txt">
                                    解决生产制造型企业周期性用工难，降低人力成本，合规完税。
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="animate-list" style="animation-delay: 0.4s">
                        <div class="img-box"><img src="../assets/images/hy/ds.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>社交电商</b>
                                <div class="content-txt">
                                    解决结算佣金、有酬无票、企业财入账等难题，推广人员无需纳高额个税。
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="animate-list" style="animation-delay: 0.6s">
                        <div class="img-box"><img src="../assets/images/hy/cy.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>餐饮服务</b>
                                <div class="content-txt">
                                    解决酒店、餐厅兼职服务人员金发放问题，代缴个税，供合规完税凭证。
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <ul data-name="box3" class="transform-panel case-box case02">
                    <li class="animate-list" style="animation-delay: 0.8s">
                        <div class="img-box"><img src="../assets/images/hy/sh.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>生活服务</b>
                                <div class="content-txt">
                                    解决保洁、保姆等生活服务类公司对私合规发佣问题，并通过活用工模式减少劳资纠纷。
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="animate-list" style="animation-delay: 1s">
                        <div class="img-box"><img src="../assets/images/hy/cx.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>共享出行</b>
                                <div class="content-txt">
                                    解决出行平台兼职司机佣金发问题，报酬发放+个税申报一化处理，助力企业财务合规。
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="animate-list" style="animation-delay: 1.2s">
                        <div class="img-box"><img src="../assets/images/hy/kf.jpg" alt=""></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>技术开发</b>
                                <div class="content-txt">
                                    解决兼职技术人员佣金发放问题，代缴个税，提供合规完税凭证。
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="pc-display">
                    <div class="bottom-small transform-panel" data-name="box4">
                        <div class="animate-list li" style="animation-delay: 0.2s">
                            <div class="padding">
                                <b>广告传媒</b>
                                <div class="txt">
                                    解决兼职设计人员佣金问题，代缴个税，提供合规完税凭证。
                                </div>
                            </div>
                        </div>

                        <div class="animate-list li" style="animation-delay: 0.4s">
                            <div class="padding">
                                <b>配送服务</b>
                                <div class="txt">
                                    解决物流、配送、同城跑腿等行业兼职骑手的佣金发放难题，为企业提供合规入账凭证。
                                </div>
                            </div>
                        </div>

                        <div class="animate-list li" style="animation-delay: 0.6s">
                            <div class="padding">
                                <b>线下促销</b>
                                <div class="txt">
                                    解决线下兼职营销推广人员佣金发放问题，为企业取得合规入账凭证。
                                </div>
                            </div>
                        </div>

                        <div class="animate-list li" style="animation-delay: 0.8s">
                            <div class="padding">
                                <b>购物返利</b>
                                <div class="txt">
                                    电商平台通过平台向用户发放购物返利，提高用户回购率和忠诚度。
                                </div>
                            </div>
                        </div>

                        <div class="animate-list li" style="animation-delay: 1s">
                            <div class="padding">
                                <b>代办服务</b>
                                <div class="txt">
                                    解决中介代办人员的佣金发放问题，解决有酬无票，规避合规风险。
                                </div>
                            </div>
                        </div>

                        <div class="animate-list li" style="animation-delay: 1.2s">
                            <div class="padding">
                                <b>自媒体平台</b>
                                <div class="txt">
                                    解决自媒体平台内容创作者佣金发放问题，内容创作者无需缴纳高额个税。
                                </div>
                            </div>
                        </div>

                        <div class="animate-list li" style="animation-delay: 1.4s">
                            <div class="padding">
                                <b>教育培训</b>
                                <div class="txt">
                                    解决教育培训机构兼职讲师佣金发放问题，兼职讲师无需缴纳高额个税。
                                </div>
                            </div>
                        </div>

                        <div class="animate-list li more" style="animation-delay: 1.6s">
                            <b>更多行业场景<br />期待与您合作</b>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="caseShow.payVisible" class="content-box overall-box">
                <ul class="scheme-box">
                    <li class="animate-list" style="animation-delay: 0.2s">
                        <div class="padding-box">
                            <div class="title-box">
                                <div class="broder"></div>
                                <b>电商B2B2C返款解决方案</b>
                                <div class="en">E-commerce B2B2C rebate solution</div>
                            </div>
                            <img src="@/assets/images/page/flows02.png" class="flows" />
                            <div class="content-txt">
                                完整的订单信息流，让支付订单的统计以及对账更清晰、更便捷。
                                建立完善的资金管理机制，确保平台资金的安全性和稳定性，避免资金风险。
                            </div>
                        </div>
                    </li>
                    <li class="animate-list" style="animation-delay: 0.4s">
                        <div class="padding-box">
                            <div class="title-box">
                                <div class="broder"></div>
                                <b>人资管理解决方案</b>
                                <div class="en">Human resource management solution</div>
                            </div>
                            <img src="@/assets/images/page/flows01.png" class="flows" />
                            <div class="content-txt">
                                帮助企业灵活用工实现费用结算便捷化，提高效率解决灵活用工支出成本问题，降低成本，方便纳税。
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="case-box case01">
                    <li class="animate-list" style="animation-delay: 0.2s">
                        <div class="img-box"><img src="../assets/images/hy/rl.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>人力资源行业</b>
                                <div class="content-txt">
                                    解决灵活用工支出成本问题。<br />降低成本，依法纳税。
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="animate-list" style="animation-delay: 0.4s">
                        <div class="img-box"><img src="../assets/images/hy/dsh.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>电商行业</b>
                                <div class="content-txt">
                                    用户订单退款、佣金发放。<br />灵活应对转账需求，一键退款。
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="animate-list" style="animation-delay: 0.6s">
                        <div class="img-box"><img src="../assets/images/hy/wl.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>物流行业</b>
                                <div class="content-txt">
                                    小件员派单佣金发放。<br />当日结算、实时到账。
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="case-box case02">
                    <li class="animate-list" style="animation-delay: 0.4s">
                        <div class="img-box"><img src="../assets/images/hy/zz.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>社交平台转账</b>
                                <div class="content-txt">
                                    打赏、赏金发放。<br />实时提现、灵活高效。
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="animate-list" style="animation-delay: 0.6s">
                        <div class="img-box"><img src="../assets/images/hy/tx.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>会员余额提现</b>
                                <div class="content-txt">
                                    从自身单位的结算账户向持卡人<br />指定银行卡账户进行款项支付
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="animate-list" style="animation-delay: 0.8s">
                        <div class="img-box"><img src="../assets/images/hy/zh.jpg"></div>
                        <div class="content-box">
                            <i class="iconfont">&#xeb0b;</i>
                            <div>
                                <b>综合行业</b>
                                <div class="content-txt">
                                    内部员工报销款发放。<br />用户账号一键授权合作，精准打款。
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="pc-display">
                    <div class="bottom-small">
                        <div class="li animate-list" style="animation-delay: 0.2s">
                            <div class="padding">
                                <b>报销发放</b>
                                <div class="txt">
                                    企业可将员工的差旅费、报销款等费用快速发放到其个人支付宝账户中，方便快捷。
                                </div>
                            </div>
                        </div>
                        <div class="li animate-list" style="animation-delay: 0.4s">
                            <div class="padding">
                                <b>渠道管理</b>
                                <div class="txt">
                                    企业可以通过平台向代理商、渠道商等支付佣金、服务费等，方便快捷，降低操作风险。
                                </div>
                            </div>
                        </div>
                        <div class="li animate-list" style="animation-delay: 0.6s">
                            <div class="padding">
                                <b>物业管理</b>
                                <div class="txt">
                                    物业公司将物业费、水电费等收费款项返还给业主，提高缴费效率，方便业主。
                                </div>
                            </div>
                        </div>
                        <div class="li animate-list" style="animation-delay: 0.8s">
                            <div class="padding">
                                <b>购物返利</b>
                                <div class="txt">
                                    电商平台通过平台向用户发放购物返利，提高用户回购率和忠诚度。
                                </div>
                            </div>
                        </div>
                        <div class="li animate-list" style="animation-delay: 1s">
                            <div class="padding">
                                <b>市场调研</b>
                                <div class="txt">
                                    市场调研机构可以通过平台向参与调研的用户发放奖励，提高调研效率
                                </div>
                            </div>
                        </div>
                        <div class="li animate-list" style="animation-delay: 1.2s">
                            <div class="padding">
                                <b>活动奖励</b>
                                <div class="txt">
                                    企业可以通过平台向参与活动的用户发放奖励，提高用户参与度和满意度。
                                </div>
                            </div>
                        </div>
                        <div class="li animate-list" style="animation-delay: 1.4s">
                            <div class="padding">
                                <b>节日福利</b>
                                <div class="txt">
                                    企业向员工发放节日福利、年终奖金等，提高员工福利待遇。
                                </div>
                            </div>
                        </div>
                        <div class="li animate-list" style="animation-delay: 1.6s">
                            <div class="padding">
                                <b>平台分润</b>
                                <div class="txt">
                                    各类互联网平台向平台用户或合作伙伴进行分润，方便快捷，有效防止资金风险。
                                </div>
                            </div>
                        </div>
                        <div class="more">
                            <b>更多行业场景<br />期待与您合作</b>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-box">
                <div class="pc-display">
                    <Footer></Footer>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import PageMenu from '@/components/PageMenu.vue'; // 菜单
import Menu from '@/components/Menu.vue'; // 菜单
import PhoneMenu from '@/components/PhoneMenu.vue'; // 菜单
import Footer from '@/components/Footer.vue'; // 底部
export default {
    name: 'ProductIntroduce',
    components: { Menu, PageMenu, Footer, PhoneMenu },
    metaInfo: {
        meta: [
            {
                // set meta
                name: '代付,B2c代付,四方支付,三方支付,付款到支付宝银行卡,企业代付,接口代付,微信支付宝收款,二维码收款,快捷支付,微信支付宝支付,商户分账,账户产品,代收,银行卡收单,微信支付宝收单,二清账户,支付解决方案',
                content:
                    '代付,B2c代付,四方支付,三方支付,付款到支付宝，付款到银行卡,企业代付,接口代付,微信收款,支付宝收款,二维码收款,快捷支付,微信支付,支付宝支付，商户分账,账户产品,代收,银行卡收单,微信收单,支付宝收单,二清账户,支付解决方案'
            }
        ]
    },
    data() {
        return {
            caseShow: {
                agileVisible: true, // 灵活用工平台
                payVisible: false // 收付平台
            }
        };
    },
    mounted() {
        document.title = '黑龙江靖峰科技有限公司';

        // 定义一个函数，用于添加动画类
        function addAnimationClass(elements, animationClass) {
            const windowHeight = window.innerHeight; // 获取窗口高度
            elements.forEach(function (div) {
                const rect = div.getBoundingClientRect(); // 获取div相对于视窗的位置
                // 检查div是否进入视窗
                if (rect.top <= windowHeight && rect.bottom >= 0) {
                    div.classList.add('animate__animated', animationClass);
                }
            });
        }
        window.addEventListener('scroll', function () {
            // 获取需要添加动画的元素
            const subhead = document.querySelectorAll('.animate-subhead'); // 副标题
            const title = document.querySelectorAll('.animate-title'); // 标题
            const cutButton = document.querySelectorAll('.animate-cutButton'); // 切换按钮
            const caseTypeL = document.querySelectorAll('.animate-caseType-left'); // 切换按钮
            const caseTypeR = document.querySelectorAll('.animate-caseType-right'); // 切换按钮
            const list = document.querySelectorAll('.animate-list'); // 切换按钮

            // 添加动画类
            addAnimationClass(subhead, 'animate__zoomIn');
            addAnimationClass(title, 'animate__slideInDown');
            addAnimationClass(cutButton, 'animate__fadeInUp');
            addAnimationClass(caseTypeL, 'animate__fadeInLeft');
            addAnimationClass(caseTypeR, 'animate__fadeInRight');
            addAnimationClass(list, 'animate__fadeIn');
        });
        // 图片效果兼容
        const images = document.querySelectorAll('.grayscale-img');
        images.forEach(function (image) {
            image.addEventListener('mouseover', function () {
                this.style.filter = 'grayscale(0%)';
            });
            image.addEventListener('mouseout', function () {
                this.style.filter = 'grayscale(100%)';
            });
        });
    },
    methods: {
        classShow() {
            this.caseShow.agileVisible = !this.caseShow.agileVisible;
            this.caseShow.payVisible = !this.caseShow.payVisible;
        }
    }
};
</script>

<style scoped lang="scss">
// 位移动画效果
.transform-panel-translate {
  animation: fadeTranslate 1s ease-in-out 0s forwards;
}

@keyframes fadeTranslate {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.page-bg {
  background: url(@/assets/images/case-bg.png) no-repeat left top;
}

.right-bg-img {
  background: url(@/assets/images/case-bg-r.png) no-repeat right top;
}

.page-big-title {
  padding: 135px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-left {
    margin-left: 69px;
    color: #333;
    font-size: 18px;
    text-align: left;

    b {
      font-size: 60px;
    }

    .button-div {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: flex-start;

      .button {
        padding: 10px 20px;
        color: #666;
        border: #d8e6ff 1px solid;
        margin: 0 10px;
        font-size: 16px;
        border-radius: 50px;
        background: #fff;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      .button:hover {
        background: #34cd8b;
        border: #34cd8b 1px solid;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
        color: #fff;
      }

      .button.hover {
        background: #34cd8b;
        border: #34cd8b 1px solid;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
        color: #fff;
      }
    }
  }

  .content-right {
    width: 560px;
    margin-right: 69px;
    color: #666;
    font-size: 15px;
    line-height: 30px;
    text-align: left;
  }
}

.content-box {
  width: 100%;

  ul {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: nowrap;
    padding: 30px 0;
  }

  li {
    flex-grow: 1;
    margin: 0 19px;
    background: #fff;
    // border-radius: 20px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 15px rgba(247, 247, 247);
  }
}

ul.scheme-box {
  .padding-box {
    width: 90%;
    margin: 85px auto;
    text-align: left;
  }

  li {
    width: 45%;
  }

  img.flows {
    width: 100%;
    max-width: 644px;
    height: 418px;
    object-fit: scale-down;
    margin: 50px auto;
  }

  .content-txt {
    width: 100%;
    font-size: 15px;
    color: #333;
    line-height: 35px;
  }

  .title-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .broder {
      width: 32px;
      height: 3px;
      background: #34cd8b;
      margin-bottom: 58px;
    }

    b {
      font-size: 30px;
      color: #333;
    }

    .en {
      color: #999;
      font-size: 10px;
      line-height: 40px;
      text-transform: uppercase;
    }
  }
}

ul.case-box {
  display: flex;
  flex-wrap: wrap;
  li {
    width: 30%;
    // height: 200px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .img-box,img{
      width: 100%;
      height: 140px;
    }
    img{
      filter: grayscale(100%);
      transition: filter 0.3s ease-in-out; /* 添加过渡效果 */
      object-fit:cover
    }
    .img-box{
      margin-bottom: 10px;
    }
    &:hover {
      box-shadow: 0px 0px 15px rgb(51, 116, 202);
      img{
        filter: grayscale(0%);
      }
    }
  }

  li .content-box {
    width: 90%;
    margin: auto;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    text-align: left;
    margin-bottom: 25px;

    i {
      font-size: 25px;
      color: #34cbcd;
      margin-right: 5px;
    }

    i,
    b {
      line-height: 30px;
    }

    b {
      font-size: 18px;
      color: #333;
    }

    .content-txt {
      color: #999;
      font-size: 15px;
      line-height: 25px;
      margin-top: 12px;
    }
  }

}

ul.case01 {
  // li:nth-of-type(1) {
  //   background: #fff url(@/assets/images/case/bgimg01.png) no-repeat right
  //     bottom;
  // }

  // li:nth-of-type(2) {
  //   background: #fff url(@/assets/images/case/bgimg02.png) no-repeat right
  //     bottom;
  // }

  // li:nth-of-type(3) {
  //   background: #fff url(@/assets/images/case/bgimg03.png) no-repeat right
  //     bottom;
  // }

  // @Change: 此处样式新增
  li:nth-of-type(n) {
    & {
      position: relative;
    }
    &::after {
      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      // background-color: $hover-bk-color;
      transition: transform 0.3s;
      transform: translateY(100%);
    }
  }
  li:nth-of-type(n):hover {
    & {
      cursor: pointer;
    }
    &::after {
      transform: translateY(0px);
    }
  }
}

ul.case02 {
  // li:nth-of-type(1) {
  //   background: #fff url(@/assets/images/case/bgimg04.png) no-repeat right
  //     bottom;
  // }

  // li:nth-of-type(2) {
  //   background: #fff url(@/assets/images/case/bgimg05.png) no-repeat right
  //     bottom;
  // }

  // li:nth-of-type(3) {
  //   background: #fff url(@/assets/images/case/bgimg06.png) no-repeat right
  //     bottom;
  // }

  // @Change: 此处样式新增
  li:nth-of-type(n) {
    & {
      position: relative;
    }
    &::after {
      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      // background-color: $hover-bk-color;
      transition: transform 0.3s;
      transform: translateY(100%);
    }
  }
  li:nth-of-type(n):hover {
    & {
      cursor: pointer;
    }
    &::after {
      transform: translateY(0px);
    }
  }
}

.bottom-small {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .li {
    width: 18%;
    margin: 30px 1%;
    padding: 35px 0;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 15px rgba(247, 247, 247);

    // @Change: 此处样式新增
    & {
      position: relative;
      cursor: pointer;
      overflow: hidden;
    }
    &::after {
      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      // background-color: $hover-bk-color;
      transition: transform 0.3s;
      transform: translateX(-101%);
    }
    &:hover {
      box-shadow: 0px 0px 15px rgb(51, 116, 202);
      &::after {
        transform: translateX(0px);
      }
    }
  }

  .more {
    border-radius: 20px;
    width: 38%;
    margin: 30px 1%;
    background: #f4fffb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 35px;

    b {
      color: #42b883;
    }
  }

  .padding {
    width: 80%;
    margin: auto;
  }

  b {
    font-size: 18px;
    color: #333;
  }

  .txt {
    font-size: 14px;
    line-height: 22px;
    color: #999;
    margin-top: 20px;
  }
}

.company-info {
  display: inline-block;
  width: 100%;
  background-color: #0a0a0a;
}

/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 900px) {
  $title-b: 1.1rem;
  $title: 1rem;
  $txt: 0.9rem;
  $txt-line: 0.9rem/1.2rem Arial;

  .right-bg-img {
    background: none;
  }

  .page-big-title {
    width: 95%;
    margin: auto;
    padding: 8vh 0;
    padding-bottom: 0vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .content-left {
      width: 100%;
      margin: 0;
      font: 0.9rem/1.5rem Arial;

      b {
        font-size: $title-b;
      }

      .button-div {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .button {
          font-size: 0.8rem;
          padding: 5px 20px;
          margin-left: 0;
          margin-right: 15px;
        }
      }
    }

    .content-right {
      width: 100%;
      margin-top: 3vh;
      font: $txt-line;
    }
  }

  .content-box {
    margin-bottom: 60px;

    ul {
      flex-direction: column;
    }

    li {
      margin: 2vh auto;
    }
  }

  ul.scheme-box {
    padding: 0;

    li {
      width: 100%;
    }

    .padding-box {
      width: 85%;
      margin: 6vh auto;
    }

    img.flows {
      max-width: 100%;
      height: 30vh;
      margin: 1vh 0;
    }

    .content-txt {
      font: $txt-line;
    }

    .title-box {
      .broder {
        margin-bottom: 2vh;
        height: 10px;
        height: 2px;
      }

      b {
        font-size: $title-b;
      }

      .en {
        line-height: 3vh;
      }
    }
  }

  ul.case-box {
    padding: 0;

    li {
      width: 100%;
      height: auto;
      padding: 3.5vh 0;
      margin: 2vh auto;
      font: $txt-line;
    }

    li .content-box {
      width: 70%;
      margin-bottom: 0px;
      display: flex;
      justify-content: flex-start;

      i {
        font-size: $title;
      }

      b {
        font-size: $title;
      }

      .content-txt {
        font: $txt-line;
        margin-top: 0vh;
      }
    }
  }

  ul.case01 {
    li:nth-of-type(1) {
      background-size: auto 10vh;
    }

    li:nth-of-type(2) {
      background-size: auto 10vh;
    }

    li:nth-of-type(3) {
      background-size: auto 10vh;
    }
  }

  ul.case02 {
    li:nth-of-type(1) {
      background-size: auto 10vh;
    }

    li:nth-of-type(2) {
      background-size: auto 10vh;
    }

    li:nth-of-type(3) {
      background-size: auto 10vh;
    }
  }
  .pc-display,
  .company-info {
    display: none;
  }
}
</style>
