<template>
    <div id="banner">
        <swiper :options="mySwiperOption">
            <swiper-slide>
                <div class="swiper-box product">
                    <div class="bg-img">
                        <div class="overall-box">
                            <img src="@/assets/images/banner/banner3.png" @click="flexibleStaffing">
                            <div class="title-box">
                                <div class="title" style="font-size: 45px;" @click="product">
                                    <b>快捷招工 轻松节税</b><span>-灵活用工平台</span>
                                </div>
                                <div class="txt" @click="product">
                                    财务合规、完税凭证、佣金结算代发、个税代缴、人力成本优化等一站式灵活用工解决方案<br class="phone-display">
                                </div>
                                <ul @click="product">
                                    <li>
                                        <div class="icon-box">
                                            A
                                        </div>
                                        <div class="right-txt">
                                            <b>灵工对接</b><br>
                                            企业根据真实业务场景，在线发布任务，签约灵工人员
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon-box">
                                            B
                                        </div>
                                        <div class="right-txt">
                                            <b>佣金代发</b><br>
                                            在线佣金结算，一键代发，实时到账

                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon-box">
                                            C
                                        </div>
                                        <div class="right-txt">
                                            <b>个税代缴</b><br>
                                            享税收优惠政策，大幅降低个税税负
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon-box">
                                            D
                                        </div>
                                        <div class="right-txt">
                                            <b>完税凭证</b><br>
                                            为企业合规取得进项发票助力企业合规完税、合法节税
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="swiper-box product">
                    <div class="bg-img">
                        <div class="overall-box">
                            <img src="@/assets/images/banner/banner1.png" @click="product">
                            <div class="title-box">
                                <div class="title" @click="product">
                                    <b>资金安全有保障</b><span>-代付平台</span>
                                </div>
                                <div class="txt" @click="product">
                                    可追溯完整资金链路信息；不介入资金流，保障用户资金安全。<br class="phone-display">合规解决商户的平台“二清”问题
                                </div>
                                <ul @click="product">
                                    <li>
                                        <div class="icon-box">
                                            A
                                        </div>
                                        <div class="right-txt">
                                            <b>快捷高效</b><br>
                                            提供多种对接方式，让接入更高效。
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon-box">
                                            B
                                        </div>
                                        <div class="right-txt">
                                            <b>安全可靠</b><br>
                                            支付功能全链路加密，订单过程可追溯。
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon-box">
                                            C
                                        </div>
                                        <div class="right-txt">
                                            <b>灵活便捷</b><br>
                                            适配各行业，提供灵活的产品对接方式。
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon-box">
                                            D
                                        </div>
                                        <div class="right-txt">
                                            <b>追溯完整链路</b><br>
                                            提供资金信息链路完整追溯，每笔收付款都轻松掌握
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="swiper-box productCollect">
                    <div class="bg-img">
                        <div class="overall-box">
                            <div class="title-box">
                                <div class="title" @click="productCollect">
                                    <b>多种收款方式</b><span>-收款平台</span>
                                </div>
                                <div class="txt" @click="productCollect">
                                    支持微信、支付宝、信用卡、银行卡、电子钱包等多种收款方式。
                                </div>
                                <ul @click="productCollect">
                                    <li>
                                        <div class="icon-box">
                                            A
                                        </div>
                                        <div class="right-txt">
                                            <b>便捷高效</b><br>
                                            支持订单一键导出，方便统计对账。
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon-box">
                                            B
                                        </div>
                                        <div class="right-txt">
                                            <b>量身定制</b><br>
                                            根据实际业务场景，支持个性化方案的定制。
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon-box">
                                            C
                                        </div>
                                        <div class="right-txt">
                                            <b>快速到账</b><br>
                                            及时响应操作，无需等待。
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon-box">
                                            D
                                        </div>
                                        <div class="right-txt">
                                            <b>追溯完整链路</b><br>
                                            提供资金信息链路完整追溯，每笔收付款都轻松掌握
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <img src="@/assets/images/banner/banner2.png" @click="productCollect">
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div slot="pagination" class="swiper-pagination"></div>
        </swiper>

    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
export default {
    name: 'MyTestVueAwesomeSwipe',
    data() {
        return {
            timer: null, // 定时器名称\n
            color: ['#5035d6', '#383ace'],
            pColor: '',

            mySwiperOption: {
                pagination: {
                    el: '.swiper-pagination', // 与slot="pagination"处 class 一致
                    clickable: true // 轮播按钮支持点击
                },
                // 自动播放
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                // 循环
                loop: true
            },
            httpImageUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F013fe45544a15e0000019ae9049657.jpg%401280w_1l_2o_100sh.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1663827624&t=884f3f882f3070c1168c89864ae1c289'

        };
    },
    created() {
        this.setTime();
    },
    beforeDestroy() {
        clearInterval(this.timer); // 清除定时器\n
        this.timer = null;
    },
    methods: {
        setTime() {
            // 每隔一分钟运行一次保存方法\n
            this.timer = setInterval(() => {
                this.saveList();
            }, 5000);
        },
        saveList() {
            const math = Math.floor(Math.random() * this.color.length); // 获取随机数
            this.pColor = this.color[math]; // 替换字体颜色
        },

        product() {
            this.$router.push('/product');
        },
        productCollect() {
            this.$router.push('/productCollect');
        },
        flexibleStaffing() {
            this.$router.push('/flexibleStaffing');
        }
    }
};

</script>
<style lang="scss" scoped>
#banner {
    width: 100%;
}

.bg-img {
    width: 100%;
    background: url(@/assets/images/case-bg-r.png) no-repeat right top;
}

.swiper-box {
    width: 100%;
    height: 90vh;
    max-height: 700px;
    color: #3e3e3e;
    background: url(@/assets/images/case-bg.png) no-repeat left top;

    img {
        height: 45vh;
        max-height: 373px;
        cursor: pointer;
    }

    .overall-box {
        padding-top: 80px;
        height: calc(90vh - 80px);
        max-height: calc(700px - 80px);
        overflow: hidden;

        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-around;
    }

    .title-box {
        width: 45%;
        margin-left: 2.8%;
        text-align: left;
    }

    .title {
        font-size: 50px;
        cursor: pointer;
        color: #1f57a6;
    }

    .txt {
        line-height: 25px;
        margin-top: 10px;
        font-size: 18px;
        cursor: pointer;
    }

    ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 8vh;

        li {
            width: 48%;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 5vh;
            margin-right: 2%;
            cursor: pointer;
        }

        .icon-box {
            width: 32px;
            height: 32px;
            margin-top: 8px;
            margin-right: 10px;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(37, 106, 202, 0.2);
            color: #fff;
        }

        b {
            line-height: 33px;
        }

        .right-txt {
            width: 90%;
        }
    }
}

::v-deep .swiper-pagination-bullet {
    width: 29px;
    height: 3px;
    display: inline-block;
    border-radius: 0;
    background: #000;
    opacity: 0.2;
}

::v-deep .swiper-pagination-bullet-active {
    opacity: 1;
    background: #34cd8b;
}

.pc-display {
    display: inline;
}

.phone-display {
    display: none;
}

/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 900px) {
    $title-b: 1.1rem;
    $title: 1rem;
    $txt: 0.9rem;
    $txt-line: 0.9rem/1.2rem Arial;

    img {
        display: none;
    }

    .swiper-box {
        height: 65vh;
        background-size: auto 60vh;
        img {
            display: none;
        }
        .bg-img {
            background: none;
        }

        .overall-box {
            height: 65vh;
            padding: 0;
            display: flex;
            align-items: center;
        }

        .title-box {
            width: 95%;
            margin: 0;
        }

        .title {
            font-size: $title-b;
        }

        .txt {
            font: $txt-line;
        }

        b {
            font-size: 1.5rem;
        }

        span {
            display: block;
            width: 100%;
        }

        ul {
            flex-direction: column;
            margin-top: 4vh;

            li {
                width: 100%;
                font: $txt-line;
                margin-bottom: 2vh;
            }

            .right-txt {
                line-height: 1vh;
                font: $txt-line;
            }

            li b {
                font-size: $title;
                line-height: 1vh;
            }

            .icon-box {
                width: 25px;
                height: 25px;
                margin-top: 0.2vh;
            }
        }
    }
}
</style>
