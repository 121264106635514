<template>
    <div style="background: #fbfbfb;">

        <div class="pc-display">
            <page-menu></page-menu>
        </div>
        <div class="phone-display">
            <Menu></Menu>
            <PhoneMenu></PhoneMenu>
        </div>
        <div class="contact-box">
            <div class="overall-box">
                <!-- <div class="contact-title">
          关于我们 <span>/ Contact Us</span>
        </div> -->
                <div class="who-box">
                    <div class="overall-box">
                        <img src="@/assets/images/page/1562916277919.png" class="left-img animate__animated animate__bounceInDown">
                        <div>
                            <div class="animate__animated animate__slideInDown">
                                <b>我们是谁？</b>
                            </div>
                            <div class="txt animate__animated animate__slideInDown">黑龙江靖峰科技有限公司<span class="pc-display"> - </span><br class="phone-display" />专业从事软件技术服务的企业。
                            </div>
                            <div class="line animate__animated animate__fadeInUp"></div>
                            <div class="animate__animated animate__fadeInUp">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们是一家专业从事软件服务的科技企业，深耕电子支付、灵活用工等数字化服务领域。公司自成立以来，以支付为核心，不断输出「支付+」创新业务，为互联网商业型企业提供安全、合规、稳定、便捷的支付解决方案，赢得了广泛的行业赞誉和商业信赖。<br /><br />

                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司旗下的「靖峰支付平台」，快速接入主流支付渠道，为企业部署集资金支付、账户管理、财资管理等功能于一体的智慧支付系统；「福象灵工平台」，通过财税筹划、佣金代发、完税凭证等一站式灵活用工解决方案，为企业用工降本增效。<br /><br />

                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;未来，靖峰科技将以支付为基，持续深耕数字化服务领域，为数字经济变革下的新业态，提供更多优质的创新实践。<br /><br />
                            </div>
                        </div>
                    </div>

                    <div class="pc-display">
                        <ul class="icon-box overall-box">
                            <li class="animate-list" style="animation-delay: 0.2s">
                                <i class="iconfont">&#xe605;</i>
                                <div class="en">
                                    development
                                </div>
                                <div class="txt">用户至上</div>
                            </li>
                            <li class="animate-list" style="animation-delay: 0.4s">
                                <i class="iconfont">&#xe624;</i>
                                <div class="en">
                                    adcisory
                                </div>
                                <div class="txt">忠于职守</div>
                            </li>
                            <li class="animate-list" style="animation-delay: 0.6s">
                                <i class="iconfont">&#xe616;</i>
                                <div class="en">
                                    interflow
                                </div>
                                <div class="txt">协同共享</div>
                            </li>
                            <li class="animate-list" style="animation-delay: 0.8s">
                                <i class="iconfont">&#xe614;</i>
                                <div class="en">
                                    extension
                                </div>
                                <div class="txt">创新引领</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="honor-box">
                    <div class="overall-box">
                        <div>
                            <div class="title animate-title">
                                <b>品牌实力 专业鉴证</b>
                            </div>
                            <div class="en animate-subhead">
                                多年深耕，沉淀专业力量；以多项行业权威资质认证，鉴证靖峰科技品牌硬实力。
                            </div>
                        </div>
                        <ul>
                            <li class="animate-list" style="animation-delay: 0.2s">
                                <img src="@/assets/images/certification/bg.png" class="bg">
                                <img src="@/assets/images/certification/4.png" class="img">
                            </li>
                            <li class="animate-list" style="animation-delay: 0.4s">
                                <img src="@/assets/images/certification/bg.png" class="bg">
                                <img src="@/assets/images/certification/2.png" class="img">
                            </li>
                            <li class="animate-list" style="animation-delay: 0.6s">
                                <img src="@/assets/images/certification/bg.png" class="bg">
                                <img src="@/assets/images/certification/3.png" class="img">
                            </li>
                            <li class="animate-list" style="animation-delay: 0.8s">
                                <img src="@/assets/images/certification/bg.png" class="bg">
                                <img src="@/assets/images/certification/1.png" class="img">
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="content-box">
                    <li>
                        <div class="animate-title">
                            <div class="title">
                                黑龙江靖峰科技有限公司
                            </div>
                            <div class="en">
                                Heilongjiang Jingfeng Technology Co. LTD
                            </div>
                        </div>
                        <div class="line animate-subhead"></div>
                        <div class="content animate-title">
                            <div class="li-box">
                                <span class="left-title">地址：</span>
                                <div class="right-txt">
                                    黑龙江省哈尔滨市道里区<br class="pc-display" />群力第五大道汇智金融企业总部B座1402
                                </div>
                            </div>
                            <div class="li-box">
                                <span class="left-title">电话：</span>
                                <div class="right-txt">
                                    400-678-5651
                                </div>
                            </div>
                            <div class="li-box">
                                <span class="left-title">邮箱：</span>
                                <div class="right-txt">
                                    jfengkeji@126.com
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="animate-title">
                            <b>微信联系</b>
                        </div>
                        <div class="qr-box animate-list" style="animation-delay: 0.2s">
                            <div class="img-box">
                                <img src="@/assets/images/qr/g.png" alt="">
                                公众号
                            </div>
                            <div class="img-box animate-list" style="animation-delay: 0.4s">
                                <img src="@/assets/images/qr/h.png" alt="">
                                商务经理
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="animate-title">
                            <div class="title">24小时服务咨询</div>
                            <b>400-678-5651</b>
                            <div class="txt">服务时间：7*24小时</div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="footer-box">
                <div class="pc-display">
                    <Footer></Footer>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import PageMenu from '@/components/PageMenu.vue';// 菜单
import Menu from '@/components/Menu.vue';// 菜单
import PhoneMenu from '@/components/PhoneMenu.vue';// 菜单
import Banner from '@/components/Banner.vue';// 轮播
import Footer from '@/components/Footer.vue';// 底部
export default {
    name: 'Product',
    components: { Menu, PageMenu, Banner, Footer, PhoneMenu },
    data() {
        return {
        };
    },
    mounted() {
        // 定义一个函数，用于添加动画类
        function addAnimationClass(elements, animationClass) {
            const windowHeight = window.innerHeight; // 获取窗口高度
            elements.forEach(function (div) {
                const rect = div.getBoundingClientRect(); // 获取div相对于视窗的位置
                // 检查div是否进入视窗
                if (rect.top <= windowHeight && rect.bottom >= 0) {
                    div.classList.add('animate__animated', animationClass);
                }
            });
        }
        window.addEventListener('scroll', function () {
            // 获取需要添加动画的元素
            const subhead = document.querySelectorAll('.animate-subhead');// 副标题
            const title = document.querySelectorAll('.animate-title');// 标题
            const cutButton = document.querySelectorAll('.animate-cutButton');// 切换按钮
            const caseTypeL = document.querySelectorAll('.animate-caseType-left');// 切换按钮
            const caseTypeR = document.querySelectorAll('.animate-caseType-right');// 切换按钮
            const list = document.querySelectorAll('.animate-list');// 切换按钮

            // 添加动画类
            addAnimationClass(subhead, 'animate__zoomIn');
            addAnimationClass(title, 'animate__slideInDown');
            addAnimationClass(cutButton, 'animate__fadeInUp');
            addAnimationClass(caseTypeL, 'animate__fadeInLeft');
            addAnimationClass(caseTypeR, 'animate__fadeInRight');
            addAnimationClass(list, 'animate__fadeIn');
        });
    }
};
</script>
<style scoped lang="scss">
.contact-box {
  width: 100%;
  background: url(@/assets/images/page/contace-bg.jpg) top no-repeat;
  background-size: 100% 520px;
}

.contact-title {
  width: 100%;
  margin: auto;
  color: #333;
  font-size: 26px;
  text-align: left;
  margin-top: 150px;
  margin-bottom: 50px;

  span {
    font-size: 14px;
    color: #999;
  }
}

.content-box {
  width: 100%;
  display: flex;
  font-size: 15px;
  text-align: left;
  margin-bottom: 30px;

  li {
    flex-grow: 1;
    background: #fff;
    padding: 70px 5%;
  }

  li:nth-of-type(1) {
    .title {
      font-size: 18px;
      color: #10a46b;
    }

    .en {
      color: #999;
      font-size: 12px;
      line-height: 25px;
    }

    .line {
      width: 10px;
      height: 1px;
      background: #999;
      margin: 35px 0;
    }

    .content {
      line-height: 25px;
      color: #333;

      .li-box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;
      }

      .right-txt {
        width: calc(100% - 50px);
      }

      .left-title {
        width: 50px;
        color: #999;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
      }

      span {
        color: #999;
      }
    }
  }

  li:nth-of-type(2) {
    b {
      color: #333;
      font-size: 18px;
    }

    .qr-box {
      display: flex;
      flex-wrap: nowrap;
    }

    .img-box {
      max-width: 130px;
      width: 40%;
      margin: 40px 5% 0 0;
      text-align: center;

      img {
        width: 100%;
      }
    }
  }

  li:nth-of-type(3) {
    color: #fff;
    background: #10a46b;
    display: flex;
    flex-direction: column;

    b {
      font-size: 34px;
    }

    .title {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .txt {
      margin-top: 10px;
    }
  }
}

ul.icon-box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 90px;

  li {
    display: flex;
    flex-direction: column;
  }

  i {
    font-size: 38px;
    color: #1f57a6;
    height: 40px;
    line-height: 40px;
  }

  .en {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 10px;
  }

  .txt {
    font-size: 20px;
  }
}

.who-box {
  width: 100%;
  background: #fff;
  background-size: 25%;
  text-align: left;
  margin-top: 120px;
  margin-bottom: 50px;
  padding: 80px 0;

  .overall-box {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    line-height: 32px;
    font-size: 15px;
  }

  b {
    font-size: 38px;
  }

  .txt {
    font-size: 14px;
  }

  .line {
    width: 10px;
    height: 1px;
    background: #999;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .left-img {
    width: 40%;
    margin-right: 50px;
  }
}

.honor-box {
  margin-bottom: 50px;
  background: #fff;
  padding: 80px 0;
  text-align: left;


  .overall-box {
    width: 90%;
    align-items: center;
    line-height: 32px;
    font-size: 15px;
  }

  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    li {
      width: 21.5%;
      position: relative;
    }

    li img.bg {
      width: 100%;
      margin: auto;
    }

    li img.img {
      width: 73.8%;
      position: absolute;
      left: 13%;
      top: 10.7%;
      z-index: 0;
    }
  }

  .title {
    font-size: 34px;
    color: #2c3e50;
  }

  .en {
    color: #2c3e50;
    font-size: 15px;
    margin-top: 1vh;
    line-height: 20px;
  }
}

/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 900px) {
  $title-b: 1.1rem;
  $title: 1rem;
  $txt: 0.9rem;
  $txt-line: 0.9rem/1.2rem Arial;

  .who-box {
    padding: 5vh 0;
    margin-top: 3vh;

    .overall-box {
      flex-direction: column;
      font: $txt-line;
    }

    .left-img {
      width: 80%;
      margin: auto;
      margin-bottom: 4vh;
    }

    b {
      font-size: $title-b;
    }

    .txt {
      font: $txt-line;
      margin-top: 1vh;
    }

    .line {
      margin-top: 2vh;
      margin-bottom: 3vh;
    }
  }

  .honor-box {
    margin-bottom: 50px;
    background: #fff;
    padding: 80px 0;
    text-align: left;


    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 50px;

      li {
        width: 100%;
        position: relative;
        margin-top: 30px;
      }

      li img.bg {
        width: 100%;
        margin: auto;
      }

      li img.img {
        width: 73.8%;
        position: absolute;
        left: 13%;
        top: 10.7%;
        z-index: 0;
      }
    }

  }

  .content-box {
    flex-direction: column;
    padding-bottom: 40px;
    font: $txt-line;

    li {
      padding: 5vh 10%;
    }

    li:nth-of-type(1) {
      .title {
        font-size: $title;
      }

      .content {
        font: $txt-line;
      }
    }

    li:nth-of-type(2) {
      b {
        font-size: $title;
      }
    }

    li:nth-of-type(3) {
      b {
        font-size: 1.8rem;
      }

      .title {
        margin-top: 0;
      }
    }
  }
}
</style>
