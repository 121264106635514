import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Product from '../views/Product.vue';
import ProductCollect from '../views/ProductCollect.vue';
import Cases from '../views/Cases.vue';
import Contact from '../views/Contact.vue';
import About from '../views/About.vue';
import FlexibleStaffing from '../views/FlexibleStaffing.vue';

import Test from '../test/test.vue';

// 解决点击重复路由报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    }, {
        path: '/product',
        name: 'Product',
        component: Product
    }, {
        path: '/productCollect',
        name: 'ProductCollect',
        component: ProductCollect
    }, {
        path: '/cases',
        name: 'Cases',
        component: Cases
    }, {
        path: '/contact',
        name: 'Contact',
        component: Contact
    }, {
        path: '/about',
        name: 'About',
        component: About
    }, {
        path: '/test',
        name: 'Test',
        component: Test
    }, {
        path: '/flexibleStaffing',
        name: 'flexibleStaffing',
        component: FlexibleStaffing
    }
]


const router = new VueRouter({
    routes
})

export default router;
