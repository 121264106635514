<template>
    <div>
        <svg width=300 height=300 viewbox="0 0 300 300">
            <path d="M 0 150 L 300 150" class="line" :stroke-dashoffset="dashOffset" stroke-dasharray="9"></path>
        </svg>

    </div>
</template>
  
  
<script>
export default {
  name: 'Test',
  data() {
    return {
        dashOffset: 0
    }
  },
  mounted() {
    setInterval(() => {
        this.dashOffset -=5
    }, 200);
  }
}
</script>
<style scoped lang="scss">
    .line {
      fill: red;
      stroke: #06244a;
      stroke-width: 2px;
    }

</style>
  