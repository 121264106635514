<template>
    <div
        id="menu"
        class="user-select"
    >
        <div :class="place <= 80 ? 'menu-detail' : 'menu-detail-dist'">
            <div class="max-width">
                <div class="logo"></div>
                <ul class="nav">
                    <li
                        class="nav-item"
                        @click="home"
                    ><span
                        class="nav-item-title"
                        :class="{ hover: isActive('/') }"
                    >首 页</span>
                    </li>
                    <li class="nav-item">
                        <span
                            class="nav-item-title"
                            :class="{ hover: isActive('/product') || isActive('/productCollect') || isActive('/flexibleStaffing') }"
                        >产品介绍</span>
                        <!-- 下拉菜单 -->
                        <div class="dropdown-content">
                            <ul class="dropdown-menu">
                                <li @click="flexibile">
                                    <div class="left-box">
                                        <div class="icon-box">
                                            <i class="iconfont">&#xe603;</i>
                                        </div>
                                        <div class="title-box">
                                            <div>灵活用工平台</div>
                                            <div class="txt">更注重安全性与稳定性</div>
                                        </div>
                                    </div>
                                    <div class="right-box">
                                        <i class="iconfont">&#xeb94;</i>
                                    </div>
                                </li>
                                <div class="wire"></div>
                                <li @click="product">
                                    <div class="left-box">
                                        <div class="icon-box">
                                            <i class="iconfont">&#xe603;</i>
                                        </div>
                                        <div class="title-box">
                                            <div>代付平台</div>
                                            <div class="txt">更注重安全性与稳定性</div>
                                        </div>
                                    </div>
                                    <div class="right-box">
                                        <i class="iconfont">&#xeb94;</i>
                                    </div>
                                </li>
                                <div class="wire"></div>
                                <li @click="productCollect">
                                    <div class="left-box">
                                        <div class="icon-box">
                                            <i class="iconfont">&#xe63f;</i>
                                        </div>
                                        <div class="title-box">
                                            <div>收款平台</div>
                                            <div class="txt">支持多渠道支付，轻松开启收款之旅</div>
                                        </div>
                                    </div>
                                    <div class="right-box">
                                        <i class="iconfont">&#xeb94;</i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        class="nav-item"
                        @click="cases"
                    ><span
                        class="nav-item-title"
                        :class="{ hover: isActive('/cases') }"
                    >行业案例</span></li>
                    <li
                        class="nav-item"
                        @click="contact"
                    ><span
                        class="nav-item-title"
                        :class="{ hover: isActive('/contact') }"
                    >关于我们</span></li>
                </ul>
                <div
                    class="qr-box"
                    @click="qrPop"
                >
                    <i class="iconfont">&#xe646;</i>
                </div>
            </div>
        </div>

        <div
            v-show="qrShow"
            class="qrPop-bg"
            @touchmove.prevent
            @click="qrPop"
            @mousewheel.prevent
        >
            <div class="qrPop-box">
                <div class="qr-div">
                    <div>
                        <img src="@/assets/images/qr/g.png">
                        公众号
                    </div>
                    <div>
                        <img src="@/assets/images/qr/h.png">
                        商务经理
                    </div>
                </div>
                <div class="tel-box">
                    <div class="padding">
                        <div>24小时服务咨询</div>
                        <b>400-678-5651</b><br>
                        服务时间：7*24小时
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'MenuView',
    data() {
        return {
            place: 0,
            qrShow: false
        };
    },
    computed: {
        isActive() {
            return (route) => {
                return this.$route.path === route;
            };
        }
    },
    created() {
        document.title = '黑龙江靖峰科技有限公司-企业代收代付平台';
        window.pageYOffset = 0;
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    },
    mounted() {
        // 监听页面滚动事件
        window.addEventListener('scroll', this.scrolling);
        window.addEventListener('hashchange', this.menuHover);
    },
    methods: {
        scrolling(place) {
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            // 判断是否到了最顶部
            if (scrollTop >= 0) {
                this.place = scrollTop;
            }
            if (scrollTop <= 0) {
                this.place = scrollTop;
            }
        },
        menuHover(site) {
            const urlHash = window.location.hash;
            this.site = urlHash;
        },
        home() {
            this.$router.push('/');
            console.log(window.scrollY);
            if (window.scrollY > 0) {
                this.scrollToTop();
            }
        },
        product() {
            this.$router.push('/product');
            if (window.scrollY > 0) {
                this.scrollToTop();
            }
        },
        flexibile() {
            this.$router.push('/flexibleStaffing');
            if (window.scrollY > 0) {
                this.scrollToTop();
            }
        },
        productCollect() {
            this.$router.push('/productCollect');
            if (window.scrollY > 0) {
                this.scrollToTop();
            }
        },
        cases() {
            this.$router.push('/cases');
            if (window.scrollY > 0) {
                this.scrollToTop();
            }
        },
        contact() {
            this.$router.push('/contact');
            if (window.scrollY > 0) {
                this.scrollToTop();
            }
        },
        ios() {
            window.open('https://platform.jfengkeji.com/admin/');
        },
        qrPop() {
            this.qrShow = !this.qrShow;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动到顶部
            });
        }
    }
};
</script>
<style scoped lang="scss">
ul.nav {
  width: 45%;
  position: relative;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  li.nav-item {
    cursor: pointer;
    transition: all 0.3s linear;
    position: relative;

    &:hover {
      color: #00ab9f;
      transition: all .3s ease-out 0s;
    }

    .nav-item-title {
      position: relative;
      display: block;
      height: inherit;
      width: inherit;

      &:hover {
        color: #00ab9f;

        &::before {
          transform: scale(1);
        }
      }
    }

    &:hover .dropdown-content {
      height: 305px;
      cursor: default;

    }
  }

  .hover {
    color: #00ab9f;
  }
}

.qr-box .iconfont {
  font-size: 29px;
  margin-right: 40px;
  cursor: pointer;

  &:hover {
    color: #19cd8b;
    transition: all .3s ease-out 0s;
  }
}

// 下拉菜单
.dropdown-content {
  position: absolute;
  top: 80px; // 为导航栏高度
  left: -20px; // 设置为0, 不然会直接定位到父元素下方
  height: 0; // 下拉初始高度
  overflow: hidden;
  transition: 0.4s;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

ul.dropdown-menu {
  background: #fff;
  border-radius: 20px;
  padding: 1px 25px;
  color: #333;
  line-height: 25px;

  .wire {
    width: 100%;
    border-top: 1px #f1f1f1 dashed;
  }

  li {
    width: 280px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin: 25px 0;
    cursor: pointer;

    &:hover {
      .right-box .iconfont {
        opacity: 1;
        transition: all 0.3s linear;
      }

      .icon-box {
        background: #eafaf3;
        transition: all 0.3s linear;
      }
    }

    .left-box {
      min-width: 220px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .right-box .iconfont {
      opacity: 0;
      font-size: 24px;
      color: #4dd399;
    }

    .icon-box {
      width: 38px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      border-radius: 5px;
      background: #f8f8f8;
      color: #38ce8d;
      margin-right: 15px;

      i {
        font-size: 20px;
      }
    }

    .title-box {
      color: #333;
      font-size: 18px;
      padding-top: 1px;

      .txt {
        font-size: 12px;
        color: #999;
      }
    }
  }

  li:nth-of-type(2) {
    .icon-box {
      color: #14b5e3;
    }

    &:hover {

      .icon-box {
        background: #eaf5fa;
      }
    }

    .right-box .iconfont {
      color: #14b5e3;
    }
  }

  li:nth-of-type(3) {
    .icon-box {
      color: #38ce8d;
    }

    &:hover {

      .icon-box {
        background: #eafaed;
      }
    }

    .right-box .iconfont {
      color: #4dd399;
    }
  }
  li:nth-of-type(1) {
    .icon-box {
      color: #1f57a6;
    }

    &:hover {

      .icon-box {
        background: #e7f0ff;
      }
    }

    .right-box .iconfont {
      color: #1f57a6;
    }
  }
}

#menu {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  font-size: 15px;


  .menu-detail {
    width: 100%;
    margin: auto;
    height: 86px;
    color: #333;
    transition: all .3s ease-out 0s;

    li.nav-item {
      line-height: 86px;
    }

    .logo {
      background: url(@/assets/images/logo-b.png) no-repeat left;
    }

    .max-width {
      max-width: 1520px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
    }
  }

  .menu-detail-dist {
    width: 100%;
    margin: auto;
    height: 60px;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .3s ease-out 0s;
    background: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, .05);

    ul.nav {
      width: 30%;
      padding-left: 10%;
    }

    .dropdown-content {
      top: 40px;
    }

    .button {
      color: #fff;
    }

    .logo {
      background: url(@/assets/images/logo-b.png) no-repeat left;
      background-size: 50%;
    }

    .max-width {
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
    }

  }

  .logo {
    width: 450px;
    height: 43px;
    background-size: auto 43px;
    overflow: hidden;
    margin-left: 40px;
  }

  .button {
    width: 104px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    border-radius: 50px;
    background: #333;
    margin-left: 7%;
    color: #fff;
    cursor: pointer;

    &:hover {
      color: #19cd8b;
    }
  }
}

.qrPop-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  top: 0;
  background: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;

  .qrPop-box {
    width: 390px;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;

  }

  .qr-div {
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;

    div {
      width: 35%;
      text-align: center;
    }

    img {
      width: 100%;
    }
  }

  .tel-box {
    width: 100%;
    background: #10a46b;
    color: #fff;
    text-align: left;

    .padding {
      width: 80%;
      margin: auto;
      padding: 20px;
    }

    div {
      margin-bottom: 10px;
    }

    b {
      font-size: 24px;
      line-height: 35px;
    }
  }
}

/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 900px) {}</style>
