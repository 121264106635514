<template>
  <div class="page-bg">    
    <div class="pc-display">
      <page-menu></page-menu>
    </div>
    <div class="phone-display">
      <Menu></Menu>
      <PhoneMenu></PhoneMenu>
    </div>
    <div class="who-box">
      <div class="overall-box">
        <img src="@/assets/images/page/1562916277919.png" class="left-img">
        <div>
          <b>Who are we</b>
          <div class="txt">专业从事增值服务的高新技术企业。</div>
          <div class="line"></div>
          <div>
            我们致力于为企业提供劳务派遣、灵活用工、物流、游戏娱乐、社交、直播、运营商、电商、微商等行业的专业付款方案。立足于全国市场，紧密结合各行业特点，深挖客户需求，依托强大的研发实力，融合前沿的技术理念，快速响应客户的需求变化，为行业客户提供先进、可靠、安全、高质量、易扩展的应用定制解决方案、产品解决方案以及相关的平台服务。
          </div>
        </div>
      </div>
    </div>
    <ul class="icon-box overall-box">
      <li>
        <i class="iconfont">&#xecac;</i>
        <div class="en">
          development
        </div>
        <div class="txt">研 发</div>
      </li>
      <li>
        <i class="iconfont">&#xeb66;</i>
        <div class="en">
          adcisory
        </div>
        <div class="txt">咨 询</div>
      </li>
      <li>
        <i class="iconfont">&#xeb62;</i>
        <div class="en">
          interflow
        </div>
        <div class="txt">交 流</div>
      </li>
      <li>
        <i class="iconfont">&#xeb61;</i>
        <div class="en">
          extension
        </div>
        <div class="txt">推广</div>
      </li>
    </ul>
    <div class="overall-box context-box">
      <div class="title-box">
        <b>公司环境</b>
        <div class="txt-box">
          corporate environment
        </div>
      </div>
      <div>
        
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import PageMenu from '@/components/PageMenu.vue'//菜单
import Banner from '@/components/Banner.vue'//轮播
import PhoneMenu from '@/components/PhoneMenu.vue'//菜单
import Footer from '@/components/Footer.vue'//底部
export default {
  name: 'Product',
  components: { PageMenu, Banner, Footer, PhoneMenu },
  metaInfo: {
    meta: [{                 // set meta
      name: '代付,B2c代付,四方支付,三方支付,付款到支付宝银行卡,企业代付,接口代付,微信支付宝收款,二维码收款,快捷支付,微信支付宝支付,商户分账,账户产品,代收,银行卡收单,微信支付宝收单,二清账户,支付解决方案',
      content: '代付,B2c代付,四方支付,三方支付,付款到支付宝，付款到银行卡,企业代付,接口代付,微信收款,支付宝收款,二维码收款,快捷支付,微信支付,支付宝支付，商户分账,账户产品,代收,银行卡收单,微信收单,支付宝收单,二清账户,支付解决方案'
    }]
  },
  data() {
    return {
    }
  }
}
</script>
<style scoped lang="scss">
.contact-bg {
  width: 100%;
  height: 521px;
  background: url(@/assets/images/page/about-bg.png) no-repeat;
  background-size: 100%;
  position: relative;
}

.content-box {
  width: 100%;
  display: flex;
  font-size: 15px;
  text-align: left;
  margin-top: 150px;
}

.top-about {
  width: 100%;
  background: #10a46b;
}

.who-box {
  width: 100%;
  height: 655px;
  background: #10a46b url(@/assets/images/page/about-bg.png) no-repeat;
  background-size: 25%;
  color: #fff;
  text-align: left;

  .overall-box {
    height: 655px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    line-height: 32px;
    font-size: 15px;
  }

  b {
    font-size: 38px;
  }

  .txt {
    font-size: 14px;
  }

  .line {
    width: 10px;
    height: 1px;
    background: #fff;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .left-img {
    width: 40%;
    margin-right: 50px;
  }
}

ul.icon-box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 50px 0;

  li {
    display: flex;
    flex-direction: column;
  }

  i {
    font-size: 38px;
    color: #1f57a6;
  }

  .en {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 10px;
  }

  .txt {
    font-size: 20px;
  }
}

.context-box {
  background: #fff;
  padding: 50px 0;
  .title-box {
    b {
      font-size: 34px;
      color: #2c3e50;
    }

    .txt-box {
      font-size: 15px;
      margin-top: 20px;
    text-transform: uppercase;
    }
  }
}

/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 900px) {}</style>
