<template>
    <div>
        <div class="top">
            <img
                src="@/assets/images/logo-b.png"
                alt=""
            >
            <div
                class="qr-box"
                @click="qrPop"
            >
                <i class="iconfont">&#xe646;</i>
            </div>
        </div>
        <div style="height: 6vh;"></div>
        <div
            v-show="qrShow"
            class="qrPop-bg"
            @touchmove.prevent
            @click="qrPop"
            @mousewheel.prevent
        >
            <div class="qrPop-box">
                <div class="qr-div">
                    <div>
                        <img src="@/assets/images/qr/g.png">
                        公众号
                    </div>
                    <div>
                        <img src="@/assets/images/qr/h.png">
                        商务经理
                    </div>
                </div>
                <div class="tel-box">
                    <div class="padding">
                        <div>24小时服务咨询</div>
                        <b>400-678-5651</b><br>
                        服务时间：7*24小时
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'MenuView',
    data() {
        return {
            qrShow: false
        };
    },
    methods: {
        qrPop() {
            this.qrShow = !this.qrShow;
        }
    }
};
</script>
<style scoped lang="scss">
.top {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5vh 0 1vh 0;
  background: #fff;
  border-bottom: #f6f6f6 1px solid;
  z-index: 1000;

  img {
    height: 3.5vh;
    margin-left: 5%;
  }
}
.qr-box{
  margin-right: 5%;
}
.qr-box .iconfont {
  font-size: 1.5rem;
  cursor: pointer;

}


.qrPop-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  top: 0;
  background: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;

  .qrPop-box {
    width: 80%;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
  }

  .qr-div {
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;

    div {
      width: 35%;
      text-align: center;
    }

    img {
      width: 100%;
    }
  }

  .tel-box {
    width: 100%;
    background: #10a46b;
    color: #fff;
    text-align: left;

    .padding {
      width: 80%;
      margin: auto;
      padding: 20px;
    }

    div {
      margin-bottom: 10px;
    }

    b {
      font-size: 1.5rem;
      line-height: 35px;
    }
  }
}

/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 900px) {}
</style>
