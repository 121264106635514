<template>
    <div>
        <div class="pc-display">
            <page-menu></page-menu>
        </div>
        <div class="phone-display">
            <Menu></Menu>
            <div class="title-button">
                <div class="button pitch" @click="product">支付<br />平台</div>
                <div class="button" @click="productCollect">收款<br />平台</div>
            </div>
            <PhoneMenu></PhoneMenu>
        </div>
        <div class="banner-box">
            <div class="overall-box">
                <div class="title-box">
                    <div class="en animate__animated animate__fadeInDown">
                        Exclusive payment channel
                    </div>
                    <div class="animate__animated animate__fadeInDown">
                        <b>
                            一站式灵活用工<br />
                            佣金结算服务平台
                        </b>
                    </div>
                    <ul class="animate__animated animate__fadeInDown">
                        <li>改变传统用工关系</li>
                        <li>降低企业综合成本</li>
                        <li>助力企业财税合规</li>
                    </ul>
                    <img
                        class="animate__animated animate__bounceInDown"
                        src="@/assets/images/page/banner-product-3.png"
                    />
                </div>
            </div>
        </div>
        <div class="overall-box page-content-box">
            <div class="title-box">
                <div class="animate-title">
                    <b>产品特色</b>
                    <div class="en">Product Characteristics</div>
                </div>
                <div class="wire animate-subhead"></div>
            </div>
            <ul class="feature-box">
                <li class="animate-list" style="animation-delay: 0.2s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png" />
                        <i class="iconfont">&#xe727;</i>
                    </div>
                    <div class="title">智能平台</div>
                    <div class="txt">
                        集任务发布、灵工匹配、在线签约、佣金结算、<br
                            class="pc-display"
                        />个税代缴、获取发票于一体的灵活用工智能服务系统。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 0.4s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png" />
                        <i class="iconfont">&#xe620;</i>
                    </div>
                    <div class="title">税局认证</div>
                    <div class="txt">
                        平台拥有税局颁发的正规资质，<br
                            class="pc-display"
                        />合法处理涉税事宜。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 0.6s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png" />
                        <i class="iconfont">&#xeaa0;</i>
                    </div>
                    <div class="title">四流合一</div>
                    <div class="txt">
                        全证据链采集。合同流、业务流、资金流、<br
                            class="pc-display"
                        />发票流合一，为企业沉淀真实合规证据链。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 0.8s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png" />
                        <i class="iconfont">&#xe636;</i>
                    </div>
                    <div class="title">专业服务</div>
                    <div class="txt">
                        专业财税、风控、技术、<br
                            class="pc-display"
                        />运维团队7X24小时即时响应，满足客户多元需求。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 1s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png" />
                        <i class="iconfont">&#xe817;</i>
                    </div>
                    <div class="title">资金安全</div>
                    <div class="txt">
                        与头部支付机构合作，为企业建立专属账户，<br
                            class="pc-display"
                        />链路可视，佣金发放T+0到账。
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 1.2s">
                    <div class="icon-box">
                        <img src="../assets/images/shadow.png" />
                        <i class="iconfont">&#xe651;</i>
                    </div>
                    <div class="title">成本优势</div>
                    <div class="txt">
                        税源地资源丰富，与政府、园区长期合作，<br
                            class="pc-display"
                        />享受一手税收优惠政策，为企业降本增效。
                    </div>
                </li>
            </ul>
            <div class="title-box">
                <div class="animate-title">
                    <b>产品功能</b>
                    <div class="en">product function</div>
                </div>
                <div class="wire animate-subhead"></div>
            </div>
            <div class="function-box animate-cutButton">
                <img src="@/assets/images/page/function_flexibile_l.png" alt="" />
                <img src="@/assets/images/page/function_flexibile_r.png" alt="" />
            </div>
            <div class="title-box">
                <div class="animate-title">
                    <b>产品功能</b>
                    <div class="en">product function</div>
                </div>
                <div class="wire animate-subhead"></div>
            </div>
            <ul class="product-img-box">
                <li class="animate-list" style="animation-delay: 0.2s">
                    <div class="img-box">
                        <img src="@/assets/images/page/product/deal.png" alt="" />
                        <img src="@/assets/images/page/shadow.png" class="shadow" />
                    </div>
                    <div class="title-box">
                        <div>在线签约</div>
                        <div class="txt">
                            自由职业者在线实名认证，手机电子签约，安全高效
                        </div>
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 0.4s">
                    <div class="img-box">
                        <img src="@/assets/images/page/product/task.png" alt="" />
                        <img src="@/assets/images/page/shadow.png" class="shadow" />
                    </div>
                    <div class="title-box">
                        <div>任务领取</div>
                        <div class="txt">
                            任务大厅高效匹配，按需领取任，务实时查看任务动态及相关信息
                        </div>
                    </div>
                </li>
                <li class="animate-list" style="animation-delay: 0.6s">
                    <div class="img-box">
                        <img src="@/assets/images/page/product/account.png" alt="" />
                        <img src="@/assets/images/page/shadow.png" class="shadow" />
                    </div>
                    <div class="title-box">
                        <div>账户查询</div>
                        <div class="txt">
                            随时随地管理个人电子钱包，实时查看佣金到账情况
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="footer-box">
            <div class="pc-display">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>


<script>
import Menu from '@/components/Menu.vue'; // 菜单
import PageMenu from '@/components/PageMenu.vue'; // 菜单
import PhoneMenu from '@/components/PhoneMenu.vue'; // 菜单
// import Banner from '@/components/Banner.vue'//轮播
import Footer from '@/components/Footer.vue'; // 底部
export default {
    name: 'FlexibleStaffing',
    components: { Menu, PageMenu, Footer, PhoneMenu },
    metaInfo: {
        meta: [
            {
                // set meta
                name: '代付,B2c代付,四方支付,三方支付,付款到支付宝银行卡,企业代付,接口代付',
                content:
                    '代付,B2c代付,四方支付,三方支付,付款到支付宝，付款到银行卡,企业代付,接口代付'
            }
        ]
    },
    data() {
        return {};
    },
    mounted() {
        // 定义一个函数，用于添加动画类
        function addAnimationClass(elements, animationClass) {
            const windowHeight = window.innerHeight; // 获取窗口高度
            elements.forEach(function (div) {
                const rect = div.getBoundingClientRect(); // 获取div相对于视窗的位置
                // 检查div是否进入视窗
                if (rect.top <= windowHeight && rect.bottom >= 0) {
                    div.classList.add('animate__animated', animationClass);
                }
            });
        }
        window.addEventListener('scroll', function () {
            // 获取需要添加动画的元素
            const subhead = document.querySelectorAll('.animate-subhead'); // 副标题
            const title = document.querySelectorAll('.animate-title'); // 标题
            const cutButton = document.querySelectorAll('.animate-cutButton'); // 切换按钮
            const caseTypeL = document.querySelectorAll('.animate-caseType-left'); // 切换按钮
            const caseTypeR = document.querySelectorAll('.animate-caseType-right'); // 切换按钮
            const list = document.querySelectorAll('.animate-list'); // 切换按钮

            // 添加动画类
            addAnimationClass(subhead, 'animate__zoomIn');
            addAnimationClass(title, 'animate__slideInDown');
            addAnimationClass(cutButton, 'animate__fadeInUp');
            addAnimationClass(caseTypeL, 'animate__fadeInLeft');
            addAnimationClass(caseTypeR, 'animate__fadeInRight');
            addAnimationClass(list, 'animate__fadeIn');
        });
    },
    methods: {
        product() {
            this.$router.push('/product');
        },
        productCollect() {
            this.$router.push('/productCollect');
        }
    }
};
</script>
<style scoped lang="scss">
.page-big-title {
  padding: 135px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-left {
    margin-left: 69px;
    color: #333;
    font-size: 18px;
    text-align: left;

    b {
      font-size: 60px;
    }
  }

  .content-right {
    width: 560px;
    margin-right: 69px;
    color: #666;
    font-size: 15px;
    line-height: 30px;
    text-align: left;
  }
}

.banner-box {
  width: 100%;
  height: 600px;
  background: #e7f0ff url(@/assets/images/page/bj3.png) no-repeat center bottom;
  // background: linear-gradient(to left, #32c9ff, #1b7af9);
  margin-bottom: 89px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  img {
    height: 600px;
    position: absolute;
    left: 50%;
    top: 90px;
    z-index: 10;
  }

  .title-box {
    width: 610px;
    margin-left: 70px;
    color: #333;
    text-align: left;
    padding-top: 60px;

    b {
      font-size: 50px;
    }

    .en {
      font-size: 15px;
      color: #666;
      margin-bottom: 15px;
    }

    ul {
      font-size: 14px;
      color: #666;
      line-height: 37px;
      margin-top: 30px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    li {
      background: url(@/assets/images/li-w.png) no-repeat left center;
      background-size: 17px;
      text-indent: 20px;
      width: 40%;
    }
  }
}

ul.feature-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 80px;

  li {
    width: 30%;
    height: 275px;
    margin: 0 1% 33px 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      opacity: 1;
      position: absolute;
      z-index: 1;
      left: -100%;
    }
    @keyframes slideAnimation {
      0% {
        left: -100%;
      }
      100% {
        left: 200%;
      }
    }
    &:hover {
      cursor: pointer;
      img {
        left: 200%;
        animation: slideAnimation 1.5s linear infinite;
      }
    }
  }
  .icon-box {
    width: 110px;
    height: 110px;
    border-radius: 100%;
    color: #fff;
    line-height: 110px;
    text-align: center;
    box-shadow: 0px 23px 30px #ffddcc;
    background: linear-gradient(45deg, #f99b4e, #ffae6a);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    i {
      font-size: 55px;
    }
  }

  li:nth-of-type(2) {
    .icon-box {
      box-shadow: 0px 23px 30px #d9d2ff;
      background: linear-gradient(45deg, #6b4eff, #9571ff);
    }
  }

  li:nth-of-type(3) {
    .icon-box {
      box-shadow: 0px 23px 30px #c3e4ff;
      background: linear-gradient(45deg, #017ae1, #2ba2ff);
    }
  }

  li:nth-of-type(4) {
    .icon-box {
      box-shadow: 0px 23px 30px #c6f3d6;
      background: linear-gradient(45deg, #1ad05b, #84de56);
    }
  }

  li:nth-of-type(5) {
    .icon-box {
      box-shadow: 0px 23px 30px #f8e5bd;
      background: linear-gradient(45deg, #f6d573, #fae089);
    }
  }

  li:nth-of-type(6) {
    .icon-box {
      box-shadow: 0px 23px 30px #edbbe7;
      background: linear-gradient(45deg, #db74d5, #f889ad);
    }
  }

  .title {
    font-size: 20px;
    color: #333;
    line-height: 85px;
  }

  .txt {
    font-size: 14px;
    color: #666;
    line-height: 26px;
  }
}

.function-box {
  width: 100%;
  margin-bottom: 120px;

  img {
    width: 50%;
  }
}

ul.product-img-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  li {
    width: 30%;
    max-width: 438px;
    margin-left: 10;
    cursor: pointer;

    .img-box {
      width: calc(60% - 16px);
      border: 8px #f0f0f0 solid;
      border-radius: 5px;
      position: relative;
      margin-left: 15%;
      &:hover {
        border: #3492cd 8px solid;
      }
    }

    img {
      width: 100%;
    }

    img.shadow {
      height: 33px;
      position: absolute;
      left: 0;
      bottom: -41px;
    }

    .title-box {
      width: 93%;
      margin: auto;
      margin-top: 33px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      font-size: 15px;
      color: #666;
      line-height: 30px;
      margin-left: 15%;
    }

    .txt {
      width: 70%;
      font-size: 14px;
      color: #999;
      text-align: left;
      line-height: 24px;
    }
  }
}

.title-button {
  border: #ebebeb 1px scroll;
  line-height: vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  position: fixed;
  top: 50vh;
  right: 1%;
  font-size: 0.8rem;
  z-index: 1000;

  .button {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px #bcc6d6 dashed;
    background: #fff;
    border-radius: 100%;
    color: #666;
    cursor: pointer;
    margin-bottom: 1vh;
    box-shadow: 0px 5px 10px rgb(0, 0, 0, 0.1);
  }

  .button:hover,
  .pitch {
    background: #34cd8b;
    border: 1px #34cd8b solid;
    color: #fff;
  }
}

/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 900px) {
  $title-b: 1.1rem;
  $title: 1rem;
  $txt: 0.9rem;
  $txt-line: 0.9rem/1.2rem Arial;

  .page-big-title {
    width: 95%;
    margin: auto;
    padding: 8vh 0;
    padding-bottom: 0vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .content-left {
      width: 100%;
      margin: 0;
      font: 1rem/2.5rem Arial;

      b {
        font-size: 2rem;
      }
    }

    .content-right {
      width: 100%;
      margin-top: 3vh;
      font: 1rem/1.7rem Arial;
    }
  }

  .banner-box {
    background-size: 80%;
    margin-bottom: 4vh;
    height: auto;
    padding: 4vh 0;

    img {
      display: none;
    }

    .title-box {
      width: 90%;
      margin: auto;
      padding-top: 2vh;

      b {
        font-size: $title-b;
      }

      ul {
        margin-top: 2vh;
      }

      .en {
        margin-bottom: 0.5vh;
      }

      li {
        background-size: 13px;
        text-indent: 18px;
        width: 100%;
      }

      .en,
      li {
        font: 0.9rem/1.5rem Arial;
      }
    }
  }

  .page-content-box {
    padding: 6vh 0;

    .title-box {
      margin-bottom: 5vh;
    }

    b {
      font-size: $title-b;
    }

    .en {
      line-height: 3vh;
    }

    .wire {
      margin-top: 2vh;
      height: 1px;
      width: 15px;
    }
  }

  ul.feature-box {
    li {
      width: 44%;
      height: auto;
      margin: 0 3% 7vh 3%;

      .txt {
        font: $txt-line;
      }

      .title {
        line-height: 5vh;
        margin-top: 1vh;
        font-size: $title;
      }

      .txt {
        font: $txt-line;
      }
    }

    .icon-box {
      width: 60px;
      height: 60px;
      line-height: 60px;

      i {
        font-size: 2rem;
      }
    }
  }

  .function-box {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
    }
  }

  ul.product-img-box {
    flex-direction: column;

    li {
      width: 90%;
      margin: auto;
      margin-bottom: 5.5vh;

      .title-box {
        font-size: $title;
      }

      .txt {
        font: $txt-line;
      }
    }
  }
}
</style>
