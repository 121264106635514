var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-select",attrs:{"id":"menu"}},[_c('div',{class:_vm.place <= 80 ? 'menu-detail' : 'menu-detail-dist'},[_c('div',{staticClass:"max-width"},[_c('div',{staticClass:"logo"}),_c('ul',{staticClass:"nav"},[_c('li',{staticClass:"nav-item",on:{"click":_vm.home}},[_c('span',{staticClass:"nav-item-title",class:{ hover: _vm.isActive('/') }},[_vm._v("首 页")])]),_c('li',{staticClass:"nav-item"},[_c('span',{staticClass:"nav-item-title",class:{ hover: _vm.isActive('/product') || _vm.isActive('/productCollect') || _vm.isActive('/flexibleStaffing') }},[_vm._v("产品介绍")]),_c('div',{staticClass:"dropdown-content"},[_c('ul',{staticClass:"dropdown-menu"},[_c('li',{on:{"click":_vm.flexibile}},[_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"wire"}),_c('li',{on:{"click":_vm.product}},[_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"wire"}),_c('li',{on:{"click":_vm.productCollect}},[_vm._m(4),_vm._m(5)])])])]),_c('li',{staticClass:"nav-item",on:{"click":_vm.cases}},[_c('span',{staticClass:"nav-item-title",class:{ hover: _vm.isActive('/cases') }},[_vm._v("行业案例")])]),_c('li',{staticClass:"nav-item",on:{"click":_vm.contact}},[_c('span',{staticClass:"nav-item-title",class:{ hover: _vm.isActive('/contact') }},[_vm._v("关于我们")])])]),_c('div',{staticClass:"qr-box",on:{"click":_vm.qrPop}},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.qrShow),expression:"qrShow"}],staticClass:"qrPop-bg",on:{"touchmove":function($event){$event.preventDefault();},"click":_vm.qrPop,"mousewheel":function($event){$event.preventDefault();}}},[_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-box"},[_c('div',{staticClass:"icon-box"},[_c('i',{staticClass:"iconfont"},[_vm._v("")])]),_c('div',{staticClass:"title-box"},[_c('div',[_vm._v("灵活用工平台")]),_c('div',{staticClass:"txt"},[_vm._v("更注重安全性与稳定性")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-box"},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-box"},[_c('div',{staticClass:"icon-box"},[_c('i',{staticClass:"iconfont"},[_vm._v("")])]),_c('div',{staticClass:"title-box"},[_c('div',[_vm._v("代付平台")]),_c('div',{staticClass:"txt"},[_vm._v("更注重安全性与稳定性")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-box"},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-box"},[_c('div',{staticClass:"icon-box"},[_c('i',{staticClass:"iconfont"},[_vm._v("")])]),_c('div',{staticClass:"title-box"},[_c('div',[_vm._v("收款平台")]),_c('div',{staticClass:"txt"},[_vm._v("支持多渠道支付，轻松开启收款之旅")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-box"},[_c('i',{staticClass:"iconfont"},[_vm._v("")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qrPop-box"},[_c('div',{staticClass:"qr-div"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/qr/g.png")}}),_vm._v(" 公众号 ")]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/qr/h.png")}}),_vm._v(" 商务经理 ")])]),_c('div',{staticClass:"tel-box"},[_c('div',{staticClass:"padding"},[_c('div',[_vm._v("24小时服务咨询")]),_c('b',[_vm._v("400-678-5651")]),_c('br'),_vm._v(" 服务时间：7*24小时 ")])])])
}]

export { render, staticRenderFns }