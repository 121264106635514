<template>
    <div class="overall-box">
        <div class="content-left">
            <ul class="user-select">
                <li @click="flexibile">灵活用工平台</li>
                <li @click="product">代付平台</li>
                <li @click="productCollect">收款平台</li>
                <li @click="cases">行业案例</li>
                <li @click="contact">关于我们</li>
            </ul>
            <div class="copyright user-select">Copyright © 黑龙江靖峰科技有限公司 &nbsp;&nbsp;&nbsp;<a
                target="_blank"
                href="https://beian.miit.gov.cn/#/Integrated/index"
            >黑ICP备2022009229号</a> &nbsp;&nbsp;&nbsp;

                <a
                    target="_blank"
                    href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=23010202010594"
                ><img
                    src="@/assets/images/b.png"
                > 黑公网安备 23010202010594号</a>
            </div>
        </div>
        <div class="content-right">
            <div>
                <b>商务咨询</b>
                <div>400-678-5651</div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'FooterView',
    methods: {
        home() {
            this.$router.push('/');
        },
        product() {
            this.$router.push('/product');
        },
        productCollect() {
            this.$router.push('/productCollect');
        },
        cases() {
            this.$router.push('/cases');
        },
        contact() {
            this.$router.push('/contact');
        },
        flexibile() {
            this.$router.push('/flexibleStaffing');
        },
        ios() {
            window.open('https://platform.jfengkeji.com/admin/');
        },
        about() {
            this.$router.push('/about');
        }
    }
};
</script>
<style scoped lang="scss">
.overall-box {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}

.content-left {
    height: 130px;
    margin-left: 43px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;

    ul {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    }

    li {
        cursor: pointer;
        font-size: 15px;
        // @Change: 修改了文字颜色 -> #666 ，外边距改为内边距，并添加边框
        // margin-right: 65px;
        // color: #666;
        color: #fafafa;
        padding: 0px 20px;

        // 最后一个不添加
        &:not(:last-child) {
            border-right: 2px solid #fafafa;
        }
    }

    li:hover {
        color: #19cd8b;
        transition: all 0.3s ease-out 0s;
    }

    .copyright {
        width: 100%;
        font-size: 12px;
        color: #afaeae;
        text-align: left;
        display: flex;
        align-items: center;
    }

    a {
        color: #afaeae;
        display: flex;
        align-items: center;
    }
}

.content-right {
    margin-right: 43px;
    text-align: left;
    font-size: 24px;
    width: 220px;
    display: flex;
    justify-content: flex-end;
    // @Change: 修改了文字颜色，原为  #666
    color: #fafafa;

    b {
        font-size: 18px;
        line-height: 40px;
    }
}
</style>
