<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import HomeView from '@/views/HomeView.vue';
import Product from '@/views/Product.vue';
export default {
    name: 'App',
    components: { HomeView, Product },
    mounted() {
        document.dispatchEvent(new Event('render-event'));
    }
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-family: 'typeface';
}

.footer-box{
  width: 100%;
  background: #333;
  margin-top: 50px;
}
</style>
