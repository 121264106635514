<template>
  <div id="menu">
    <div class="menu-detail">
      <ul>
        <li @click="home" :class="{ hover: isActive('/') }">
          <i class="iconfont">&#xe604;</i>
          <div>首页</div>
        </li>
        <li @click="unfold" class="product" :class="{ hover: isActive('/product') || isActive('/productCollect') }">
          <i class="iconfont">&#xeb62;</i>
          <div>产品</div>

          <div v-show="showSecond" class="product-menu">
            <div class="li" @click="flexibile">灵活用工平台</div>
            <div class="li" @click="product">代付平台</div>
            <div class="li" @click="productCollect">收款平台</div>
          </div>
        </li>
        <li @click="cases" :class="{ hover: isActive('/cases') }">
          <i class="iconfont">&#xe606;</i>
          <div>方案</div>
        </li>
        <li @click="contact" :class="{ hover: isActive('/contact') }">
          <i class="iconfont">&#xe65e;</i>
          <div>我们</div>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Menu',
  data() {
    return {
      place: 0,
      showSecond: false
    }
  },
  computed: {
    isActive() {
      return (route) => {
        return this.$route.path === route;
      };
    }
  },
  created() {
    document.title = '黑龙江靖峰科技有限公司';
    window.pageYOffset = 0;
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
    window.addEventListener("hashchange", this.menuHover);
  },
  methods: {
    scrolling(place) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 判断是否到了最顶部
      if (scrollTop >= 0) {
        this.place = scrollTop
      }
      if (scrollTop <= 0) {
        this.place = scrollTop
      }
    },
    menuHover(site) {

      let urlHash = window.location.hash
      this.site = urlHash
    },
    home() {
      this.$router.push('/');
    },
    product() {
      this.$router.push('/product');
    },
    flexibile() {
      this.$router.push('/flexibleStaffing');
    },
    productCollect() {
      this.$router.push('/productCollect');
    },
    productCollect() {
      this.$router.push('/productCollect');
    },
    cases() {
      this.$router.push('/cases');
    },
    contact() {
      this.$router.push('/contact');
    },
    ios() {
      window.open('https://platform.jfengkeji.com/admin/')
    },
    about() {
      this.$router.push('/about');
    },
    unfold() {
      this.showSecond = !this.showSecond
    }
  }
}
</script>
<style scoped lang="scss">
#menu {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 100;
  font-size: 15px;

  .hover {
    color: #19cd8b;
  }

  .menu-detail {
    width: 100%;
    margin: auto;
    height: 55px;
    color: #333;
    background: #fff;
    transition: all .3s ease-out 0s;
    border-top: #f6f6f6 1px solid;

    i {
      font-size: 1.3rem;
    }

    ul {
      width: 100%;
      height: 55px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font: 0.7rem/1rem Arial;
    }

    .product {
      position: relative;

      .product-menu {
        position: absolute;
        bottom: 50px;
        left: -200%;
        background: #fff;
        padding: 5px 10px;
        border: #f0f0f0 1px solid;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, .05);
        border-radius: 10px;

        .li {
          width: 100%;
          min-width: 115px;
          border-bottom: #f0f0f0 1px solid;
          height: 40px;
          line-height: 40px;
          margin-bottom: 5px;
          color: #666;
          font-size: 0.8rem;
        }
      }
    }
  }

}
</style>