<template>
    <div class="pc-div">
        <div class="pc-display">
            <page-menu></page-menu>
        </div>
        <div class="phone-display">
            <Menu></Menu>
            <PhoneMenu></PhoneMenu>
        </div>
        <Banner></Banner>
        <div
            class="serve-bg-box"
        >
            <div class="overall-box">
                <!-- 我们能提供的服务 -->
                <div
                    class="title-box"
                >
                    <b class="animate-title">我们能提供的服务</b>
                    <div
                        class="txt-box animate-subhead"
                    >
                        紧密结合各行业特点，深挖客户需求，依托强大的研发实力，融合前沿的技术理念。
                    </div>
                </div>
                <ul class="serve-box">
                    <li
                        class="animate-list"
                        style="animation-delay: 0.2s;"
                    >
                        <div class="padding">
                            <img src="@/assets/images/serve/icon-02.png" />
                            <div class="right-box">
                                <b>灵活用工服务</b>
                                <div class="txt">
                                    为企业提供一站式灵活用工、佣金下发、节税服务，助力企业实现税务合规优化，为企业降本增效。
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        class="animate-list"
                        style="animation-delay: 0.4s;"
                    >
                        <div class="padding">
                            <img src="@/assets/images/serve/icon-01.png" />
                            <div class="right-box">
                                <b>支付系统服务</b>
                                <div class="txt">
                                    通道安全稳定快速，及时响应操作。可追溯完整资金链路信息，平台不介入资金流，保证用户资金安全。
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        class="animate-list"
                        style="animation-delay: 0.6s;"
                    >
                        <div class="padding">
                            <img src="@/assets/images/serve/icon-03.png" />
                            <div class="right-box">
                                <b>收款系统服务</b>
                                <div class="txt">
                                    实时到账无需等待，订单查询、单据下载。多种付款方式，
                                    如：支付宝、微信、银行卡、云闪付等。
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- <li>
          <img src="@/assets/images/serve/icon-03.png" />
          <div class="right-box">
            <b>7*24小时服务</b>
            <div class="txt">为商家提供7*24小时服务中心,全天候提供专业数据风控，快速响应并解决专业问题。</div>
          </div>
        </li> -->
                </ul>
            </div>
        </div>
        <!-- 安全便捷 特点 -->
        <div class="trait-box">
            <div class="overall-box">
                <div class="left-box">
                    <div class="all-icon-box">
                        <img
                            src="@/assets/images/logo-p.png"
                            alt=""
                        />
                        <div class="icon-content">
                            <ul class="icon-box">
                                <li>
                                    <img
                                        src="@/assets/images/support/icon-01.png"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="@/assets/images/support/icon-02.png"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="@/assets/images/support/icon-03.png"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="@/assets/images/support/icon-04.png"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="@/assets/images/support/icon-05.png"
                                    />
                                </li>
                                <li>
                                    <img
                                        src="@/assets/images/support/icon-06.png"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="radiate-box">
                        <div
                            class="radiate"
                            style="width: 100%; height: 100%"
                        >
                            <div
                                class="radiate"
                                style="width: 75%; height: 75%"
                            >
                                <div
                                    class="radiate"
                                    style="width: 65%; height: 65%"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 手机显示的 -->
                <div class="phon-left-box">
                    <img
                        src="@/assets/images/phone-emit.png"
                    />
                </div>
                <div class="right-box">
                    <b class="animate-title">安全、便捷、易扩展</b>
                    <div class="txt animate-subhead">支持后台操作及API模式</div>
                    <ul class="animate-cutButton">
                        <li>
                            - 支持微信、支付宝、信用卡、银行卡、电子钱包等多种收付方式。
                        </li>
                        <li>
                            - 统一支付渠道配置，为商户设置主备支付通道，确保交易通道流畅。
                        </li>
                        <li>
                            -
                            优质通道，降低收款成本。快速入网，建立白名单机制，降低支付失败风险。
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 解决方案 -->
        <div class="overall-box">
            <div
                class="title-box"
                style="margin-bottom: 20px"
            >
                <b class="animate-title">解决方案</b>
                <div class="txt-box pc-display animate-subhead">
                    快速响应客户的需求变化，为行业客户提供先进、可靠、安全、高质量、易扩展<br />
                    的应用定制解决方案、产品解决方案以及相关的平台服务。
                </div>
                <div class="txt-box phone-display">
                    快速响应客户的需求变化，提供高质量应用定制解决方案及相关的平台服务。
                </div>
            </div>
            <div class="new-program-content">
                <div class="button-box animate-cutButton">
                    <div
                        class="button"
                        :class="{ 'button-hover': blockFlow.agileVisible }"
                        @click="showScheme"
                    >
                        灵工系统
                    </div>
                    <div
                        class="button"
                        :class="{ 'button-hover': blockFlow.payVisible }"
                        @click="showScheme"
                    >
                        支付系统
                    </div>
                </div>
            </div>
            <!-- 灵工系统 -->
            <div
                v-show="blockFlow.agileVisible"
                class="flow-box-content"
            >
                <img
                    src="@/assets/images/flow/agile.svg"
                    style="width: 90%; margin: auto"
                />
            </div>
            <!-- 支付解决方案 -->
            <div
                v-show="blockFlow.payVisible"
                class="flow-box-content"
            >
                <img src="@/assets/images/flow/01.png" />
                <img
                    src="@/assets/images/flow/02.png"
                    class="two"
                />
            </div>
        </div>
        <div class="product-box">
            <div class="overall-box">
                <div class="title-box">
                    <div class="animate-title">
                        <b>产品展示</b>
                    </div>
                    <div class="title-button animate-cutButton">
                        <div
                            class="button user-select"
                            :class="{ pitch: productFlow.agileVisible }"
                            @click="showAgile"
                        >
                            灵活用工平台
                        </div>
                        <div
                            class="button user-select"
                            :class="{ pitch: productFlow.payVisible }"
                            @click="showPay"
                        >
                            代付平台
                        </div>
                        <!---pitch-->
                        <div
                            class="button user-select"
                            :class="{ pitch: productFlow.receipVisible }"
                            @click="showReceip"
                        >
                            收款平台
                        </div>
                    </div>
                </div>
                <div
                    v-show="productFlow.agileVisible"
                    class="content-box "
                >
                    <div class="content-left animate-caseType-left">
                        <b>灵活用工平台</b>
                        <div class="txt">一站式灵活用工佣金结算服务平台</div>
                        <ul>
                            <li>改变传统用工关系</li>
                            <li>降低企业综合成本</li>
                            <li>助力企业财税合规</li>
                        </ul>
                        <div
                            class="more"
                            @click="flexible"
                        >查看详情</div>
                    </div>
                    <img
                        src="@/assets/images/flexible-brain.png"
                        class=" animate-caseType-right"
                    />
                </div>
                <div
                    v-show="productFlow.payVisible"
                    class="content-box"
                >
                    <div class="content-left animate-caseType-left">
                        <b>代付平台</b>
                        <div class="txt">适用各种商业场景和客户需求</div>
                        <ul>
                            <li>通畅的交易通道</li>
                            <li>丰富的使用场景</li>
                            <li>便捷的应用接口</li>
                            <li>完善的售前售后服务</li>
                        </ul>
                        <div
                            class="more"
                            @click="productCollect"
                        >查看详情</div>
                    </div>
                    <img
                        src="@/assets/images/product-brain.png"
                        class=" animate-caseType-right"
                    />
                </div>
                <div
                    v-show="productFlow.receipVisible"
                    class="content-box"
                >
                    <div class="content-left  animate-caseType-left">
                        <b>收款平台</b>
                        <div class="txt">支持多渠道支付，轻松开启收款之旅</div>
                        <ul>
                            <li>支付功能全链路加密</li>
                            <li>统计对账轻松便捷</li>
                            <li>灵活的产品对接方式</li>
                        </ul>
                        <div
                            class="more"
                            @click="product"
                        >查看详情</div>
                    </div>
                    <img
                        src="@/assets/images/receip-brain.png"
                        class=" animate-caseType-right"
                    />
                </div>
            </div>
        </div>
        <!-- 行业案例 -->
        <div class="overall-box case-box">
            <div
                class="title-box"
                style="margin-top: none"
            >
                <b class="animate-title">行业案例</b>
                <div class="txt-box pc-display animate-subhead">
                    适用于多种行业，为客户提供完美解决方案。
                </div>
            </div>
            <div class="ul-box">
                <div class="div animate-caseType-left">
                    <div class="title-box">
                        <b>灵活用工行业案例</b>
                        <div class="txt-box pc-display">根据行业特点提供灵活用工服务。</div>
                    </div>
                    <ul>
                        <li
                            class="animate-list"
                            style="animation-delay: 0.2s;"
                        >
                            <div class="li-padding">
                                <div class="title">生产制造</div>
                                <div class="txt">
                                    解决生产制造型企业季节性、临时性、周期性用工难题，为企业降低人力成本，合规完税。
                                </div>
                            </div>
                        </li>
                        <li
                            class="animate-list"
                            style="animation-delay: 0.4s;"
                        >
                            <div class="li-padding">
                                <div class="title">社交电商</div>
                                <div class="txt">
                                    解决电商企业给推广者结算佣金、有酬无票、企业财务入账等难题，推广人员无需缴纳高额个税。
                                </div>
                            </div>
                        </li>
                        <li
                            class="animate-list"
                            style="animation-delay: 0.6s;"
                        >
                            <div class="li-padding">
                                <div class="title">餐饮服务</div>
                                <div class="txt">
                                    解决酒店、餐厅兼职服务人员佣金发放问题，代缴个税，提供合规完税凭证。
                                </div>
                            </div>
                        </li>
                        <li
                            class="animate-list"
                            style="animation-delay: 0.8s;"
                        >
                            <div class="li-padding">
                                <div class="title">生活服务</div>
                                <div class="txt">
                                    解决保洁、保姆等生活服务类公司公对私合规发佣问题，并通过灵活用工模式减少劳资纠纷。
                                </div>
                            </div>
                        </li>
                        <li
                            class="animate-list"
                            style="animation-delay: 1s;"
                        >
                            <div class="li-padding">
                                <div class="title">共享出行</div>
                                <div class="txt">
                                    解决出行平台兼职司机佣金发放问题，报酬发放+个税申报一体化处理，助力企业财务合规。
                                </div>
                            </div>
                        </li>
                        <li
                            class="morebox animate-list"
                            style="animation-delay: 1.2s;"
                        >
                            <div
                                class="li-padding"
                                @click="cases"
                            >
                                <div class="title">了解更多可能..</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="div animate-caseType-right">
                    <div
                        class="title-box"
                        style="margin-top: 30px; margin-bottom: 0"
                    >
                        <b style="font-size: 26px; margin: 0">支付行业案例</b>
                        <div class="txt-box pc-display">
                            为企业提供安全、稳定、便捷的支付解决方案。
                        </div>
                    </div>
                    <ul>
                        <li
                            class="animate-list"
                            style="animation-delay: 0.2s;"
                        >
                            <div class="li-padding">
                                <div class="title">人力资源行业</div>
                                <div class="txt">
                                    解决灵活用工支出成本问题。降低成本，依法纳税。
                                </div>
                            </div>
                        </li>
                        <li
                            class="animate-list"
                            style="animation-delay: 0.4s;"
                        >
                            <div class="li-padding">
                                <div class="title">电商行业</div>
                                <div class="txt">
                                    用户订单退款、佣金发放。灵活应对转账需求，一键退款。
                                </div>
                            </div>
                        </li>
                        <li
                            class="animate-list"
                            style="animation-delay: 0.6s;"
                        >
                            <div class="li-padding">
                                <div class="title">物流行业</div>
                                <div class="txt">小件员派单佣金发放。当日结算、实时到账。</div>
                            </div>
                        </li>
                        <li
                            class="animate-list"
                            style="animation-delay: 0.8s;"
                        >
                            <div class="li-padding">
                                <div class="title">社交平台转账</div>
                                <div class="txt">打赏、赏金发放。实时提现、灵活高效。</div>
                            </div>
                        </li>
                        <li
                            class="animate-list"
                            style="animation-delay: 1s;"
                        >
                            <div class="li-padding">
                                <div class="title">会员余额提现</div>
                                <div class="txt">
                                    从自身单位的结算账户向持卡人指定银行卡账户进行款项支付。
                                </div>
                            </div>
                        </li>
                        <li
                            class="morebox animate-list"
                            style="animation-delay: 1.2s;"
                        >
                            <div
                                class="li-padding"
                                @click="cases"
                            >
                                <div class="title">了解更多可能..</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- footer -->
        <div class="footer-box pc-display">
            <div class="padding-box">
                <div class="overall-box">
                    <div class="open-box">
                        <div class="overall-box">
                            <div class="animate-title">
                                <b>开启您的随心收付系统之旅吧！</b>
                            </div>
                            <div class="txt animate-cutButton">
                                我们深知收付款管理艰难，实现既方便又安全的收付管理更难，一个让用户实现方便快捷的收付平台，助您省去繁琐的操作流程，<br />
                                省下时间做更有用的事，一个有效防止资金风险的平台，能够让您用着安心；<br />
                                一次试用，能让未来的您，感激现在自己的决定
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// import PaymentCode from '@/components/PaymentCode.vue'//二维码
import PageMenu from '@/components/PageMenu.vue'; // 菜单
import Menu from '@/components/Menu.vue'; // 菜单
import PhoneMenu from '@/components/PhoneMenu.vue';
import Banner from '@/components/Banner.vue'; // 轮播
import Footer from '@/components/Footer.vue'; // 底部
export default {
    name: 'HomeView',
    components: { Menu, PageMenu, Banner, Footer, PhoneMenu },
    metaInfo: {
        title: '专业从事软件技术服务的企业 - 黑龙江靖峰科技有限公司', // set a title
        meta: [
            {
                // set meta
                name: '代付,B2c代付,四方支付,三方支付,付款到支付宝银行卡,企业代付,接口代付,微信支付宝收款,二维码收款,快捷支付,微信支付宝支付,商户分账,账户产品,代收,银行卡收单,微信支付宝收单,二清账户,支付解决方案',
                content:
                    '代付,B2c代付,四方支付,三方支付,付款到支付宝，付款到银行卡,企业代付,接口代付,微信收款,支付宝收款,二维码收款,快捷支付,微信支付,支付宝支付，商户分账,账户产品,代收,银行卡收单,微信收单,支付宝收单,二清账户,支付解决方案'
            }
        ]
    },
    data() {
        return {
            blockFlow: {
                agileVisible: true,
                payVisible: false
            },
            productFlow: {
                agileVisible: true, // 灵活用工平台
                payVisible: false, // 代付平台
                receipVisible: false // 收款平台
            }
        };
    },
    created() {
        document.title = '黑龙江靖峰科技有限公司';
    },
    mounted() {
        // 定义一个函数，用于添加动画类
        function addAnimationClass(elements, animationClass) {
            const windowHeight = window.innerHeight; // 获取窗口高度
            elements.forEach(function (div) {
                const rect = div.getBoundingClientRect(); // 获取div相对于视窗的位置
                // 检查div是否进入视窗
                if (rect.top <= windowHeight && rect.bottom >= 0) {
                    div.classList.add('animate__animated', animationClass);
                }
            });
        }
        window.addEventListener('scroll', function () {
            // 获取需要添加动画的元素
            const subhead = document.querySelectorAll('.animate-subhead');// 副标题
            const title = document.querySelectorAll('.animate-title');// 标题
            const cutButton = document.querySelectorAll('.animate-cutButton');// 切换按钮
            const caseTypeL = document.querySelectorAll('.animate-caseType-left');// 切换按钮
            const caseTypeR = document.querySelectorAll('.animate-caseType-right');// 切换按钮
            const list = document.querySelectorAll('.animate-list');// 切换按钮

            // 添加动画类
            addAnimationClass(subhead, 'animate__zoomIn');
            addAnimationClass(title, 'animate__slideInDown');
            addAnimationClass(cutButton, 'animate__fadeInUp');
            addAnimationClass(caseTypeL, 'animate__fadeInLeft');
            addAnimationClass(caseTypeR, 'animate__fadeInRight');
            addAnimationClass(list, 'animate__fadeIn');
        });
    },
    methods: {
        home() {
            this.$router.push('/');
        },
        product() {
            this.$router.push('/product');
        },
        flexible() {
            this.$router.push('/flexibleStaffing');
        },
        productCollect() {
            this.$router.push('/productCollect');
        },
        cases() {
            this.$router.push('/cases');
        },
        contact() {
            this.$router.push('/contact');
        },
        ios() {
            window.open('https://platform.jfengkeji.com/admin/');
        },
        about() {
            this.$router.push('/about');
        },
        showScheme() {
            this.blockFlow.agileVisible = !this.blockFlow.agileVisible;
            this.blockFlow.payVisible = !this.blockFlow.payVisible;
        },
        showAgile() {
            // 灵工
            this.productFlow.agileVisible = true;
            this.productFlow.payVisible = false;
            this.productFlow.receipVisible = false;
        },
        showPay() {
            // 代付平台
            this.productFlow.agileVisible = false;
            this.productFlow.payVisible = true;
            this.productFlow.receipVisible = false;
        },
        showReceip() {
            // 收款平台
            this.productFlow.agileVisible = false;
            this.productFlow.payVisible = false;
            this.productFlow.receipVisible = true;
        }
    }
};
</script>

<style scoped lang="scss">

.animated {
  opacity: 1; /* 进入视窗后显示元素 */
}
/* 全局动画类定义 */
.my-animation {
  opacity: 0; /* 初始状态隐藏元素 */
    animation-duration: 1s;
    animation-fill-mode: both;
  // transition: opacity 0.5s ease; /* 过渡效果 */
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rootPanel {
  overflow: hidden;
  transform: translateX(100%);
  transition: all 2s;
}
.rootPanelFadeIn {
  overflow: hidden;
  transform: translateX(0);
  transition: all 2s;
}

.title-box {
  width: 100%;
  height: 136px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 90px;

  b {
    font-size: 34px;
  }

  .txt-box {
    font-size: 15px;
    line-height: 30px;
    color: #999;
    margin-top: 20px;
  }
}

.serve-bg-box {
  background: linear-gradient(to bottom, #f6faff, #fff, #fff, #fff);
  margin-top: 30px;
  padding-bottom: 60px;
}

.serve-box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;

  li {
    width: 355px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: #fff 1px solid;
    .padding {
      width: 90%;
      margin: 25px auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    img {
      width: 45px;
      height: 45px;
    }
    &:hover {
      background: #f5fdf9;
      border-radius: 10px;
      border: #fff 1px solid;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      transition: background 0.3s ease-in-out;
      transition: box-shadow 0.3s ease-in-out;
    }
  }

  .right-box {
    width: calc(100% - 65px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: left;

    b {
      font-size: 18px;
      color: #0f0f0f;
    }

    .txt {
      font-size: 15px;
      line-height: 30px;
      color: #777;
      margin-top: 10px;
    }
  }
}

.trait-box {
  width: 100%;
  overflow: hidden;
  background: #ebf0f8;
  margin-top: 100px;

  .overall-box {
    height: 665px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .phon-left-box {
    display: none;
  }

  .left-box {
    display: inline;
    width: 340px;
    height: 340px;
    position: relative;
    right: 65%;
    left: 0;

    .radiate-box {
      position: absolute;
      top: -50%;
      left: -50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200%;
      height: 200%;
      border: none;
      overflow: hidden;
    }

    .radiate {
      border: 1px dashed #cedcea;
      box-sizing: border-box;
      border-radius: 50%;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: scaleAnimation 2s infinite;
      position: absolute;
    }
    @keyframes scaleAnimation {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .all-icon-box {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-content,
    ul.icon-box {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    .icon-content,
    li {
      position: absolute;
    }

    ul.icon-box {
      position: relative;
    }

    li {
      width: 80px;
      height: 80px;
      background: #1677ff;
      text-align: center;
      border-radius: 10px;
      top: 0px;
      left: 0;
      margin-left: 38%;
      margin-top: -30px;
      z-index: 10;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease; /* 添加过渡效果 */
      &:hover{
        border-radius: 15px;
        width: 100px;
        height: 100px;
        transform: translate(-12px, -12px); /* 通过平移来实现从中间点放大 */
      }
    }
    li:nth-of-type(2) {
      top: 90px;
      left: 180px;
      background: #fff;

    }

    li:nth-of-type(3) {
      top: 280px;
      left: 160px;
      background: #5a86c5;
    }

    li:nth-of-type(4) {
      top: 340px;
      left: 0;
      background: #231f20;
    }

    li:nth-of-type(5) {
      top: 280px;
      left: -160px;
      background: #fff;
    }

    li:nth-of-type(6) {
      top: 90px;
      left: -180px;
      background: #49c265;
    }
  }

  .right-box {
    width: 45%;
    height: 340px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: #333;

    ul {
      margin-top: 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    li {
      color: #666;
      font-size: 18px;
      line-height: 38px;
      text-align: left;
    }
  }

  b {
    font-size: 48px;
  }

  .txt {
    font-size: 34px;
    margin-top: 25px;
  }
}

.flow-box-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 55%;
    margin-left: 5%;
  }

  img.two {
    width: 25%;
    margin-right: 5%;
  }
}

.product-box {
  width: 100%;
  background: #ebf0f8;
  padding-bottom: 150px;
  margin-top: 100px;

  .title-box {
    height: auto;
  }

  .title-button {
    margin-top: 60px;
    line-height: 45px;
    display: flex;
    justify-content: center;
  }

  .button {
    margin: 0 10px;
    padding: 0 50px;
    border: 1px #bcc6d6 dashed;
    border-radius: 100px;
    color: #666;
    cursor: pointer;
  }

  .button:hover,
  .pitch {
    background: #34cd8b;
    border: 1px #34cd8b solid;
    color: #fff;
  }

  .content-box {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: url(@/assets/images/product-bgimg.png) no-repeat right top;
  }

  .content-left {
    text-align: left;

    b {
      font-size: 50px;
      color: #2c3e50;
    }

    .txt {
      font-size: 30px;
      color: #383c41;
      margin-top: 10px;
    }

    ul {
      font-size: 22px;
      line-height: 45px;
      color: #666;
      margin-top: 50px;
      padding-left: 20px;
    }

    li {
      background: url(@/assets/images/li.png) no-repeat left center;
      background-size: auto 40%;
      text-indent: 25px;
    }

    .more {
      margin-top: 40px;
      width: 200px;
      height: 42px;
      color: #7788a4;
      border-radius: 50px;
      // border: #bcc6d6 1px solid;
      background: rgba(255, 255, 255, 0.5);
      text-align: center;
      line-height: 42px;
      margin-left: 20px;
      cursor: pointer;
    }

    .more:hover {
      background: rgba(255, 255, 255, 1);
      color: #34cd8b;
    }
  }
}

.case-box .title-box {
  margin-bottom: 30px;
}

.case-box .ul-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 280px;

  .title-box {
    margin-top: 30px;
    margin-bottom: 0;

    b {
      font-size: 26px;
      margin: 0;
    }
  }

  .div {
    width: 49%;
    background: #f9f9fb;
    padding-bottom: 30px;
  }

  ul {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: 48%;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0px 0px 15px rgba(218, 228, 239, 0.5);
      margin: 12px 0;
      min-height: 130px;
      cursor: pointer;
    }

    li:hover {
      width: 48%;
      background: #fff;
      border-radius: 3px;
      box-shadow: 0px 0px 15px rgb(183, 207, 233);
    }

    .li-padding {
      width: 90%;
      margin: auto;
      padding: 20px 0;
      text-align: left;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #2c3e50;
      }

      .txt {
        margin-top: 10px;
        font-size: 14px;
      }
    }

    .morebox {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      div {
        text-align: center;
      }
    }
  }
}

// .case-box ul {
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;

//   li {
//     width: 30%;
//     height: 200px;
//     margin: 0 1% 60px;
//     border-radius: 20px;
//     box-shadow: 0px 5px 5px rgba(234, 234, 234);
//     display: flex;
//     align-items: center;
//     overflow: hidden;
//   }

//   li:nth-of-type(1) {
//     background: url(@/assets/images/case/bgimg01.png) no-repeat right bottom;
//   }

//   li:nth-of-type(2) {
//     background: url(@/assets/images/case/bgimg02.png) no-repeat right bottom;
//   }

//   li:nth-of-type(3) {
//     background: url(@/assets/images/case/bgimg03.png) no-repeat right bottom;
//   }

//   li:nth-of-type(4) {
//     background: url(@/assets/images/case/bgimg04.png) no-repeat right bottom;
//   }

//   li:nth-of-type(5) {
//     background: url(@/assets/images/case/bgimg05.png) no-repeat right bottom;
//   }

//   li:nth-of-type(6) {
//     background: url(@/assets/images/case/bgimg06.png) no-repeat right bottom;
//   }

//   li .content-box {
//     width: 70%;
//     margin-left: 4.5%;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: flex-start;
//     align-items: flex-start;
//     text-align: left;

//     i {
//       font-size: 25px;
//       color: #34cbcd;
//       margin-right: 5px;
//     }

//     i,
//     b {
//       line-height: 30px;
//     }

//     .content-txt {
//       color: #999;
//       font-size: 15px;
//       line-height: 25px;
//       margin-top: 12px;
//     }

//     .li-content-txt {
//       width: 80%;
//     }
//   }
// }

.case-box .more-box {
  width: 255px;
  height: 43px;
  line-height: 43px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 100px;
  padding: 0 45px;
  border-radius: 140px;
  box-shadow: 0px 5px 5px rgba(234, 234, 234);
  color: #999;
  cursor: pointer;
}

.footer-box {
  width: 100%;
  height: 387px;
  background: #ebf0f8;
  margin-top: 175px;

  .padding-box {
    background: #333;
    padding-top: 212px;
    position: relative;
  }

  .open-box {
    width: 100%;
    background: #f00;
  }

  .open-box .overall-box {
    position: absolute;
    top: -185px;
    padding: 70px 0;
    background: linear-gradient(to right, #56dafb, #4e66ff, #614bd6, #614bd6);
    border-radius: 20px 100px 20px 100px;
    color: #fff;
    box-shadow: 0px 15px 30px rgba(89, 66, 210, 0.16);

    b {
      font-size: 36px;
    }

    .txt {
      font-size: 18px;
      line-height: 45px;
      margin-top: 46px;
    }
  }
}

.new-program-content {
  width: 100%;

  .button-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;

    .button {
      line-height: 45px;
      margin: 0 10px;
      padding: 0 50px;
      border: 1px #bcc6d6 dashed;
      border-radius: 100px;
      color: #666;
      cursor: pointer;
    }

    .button:hover {
      color: #fff;
      background: #34cd8b;
      border: #34cd8b 1px solid;
    }

    .button-hover {
      color: #fff;
      background: #34cd8b;
      border: #34cd8b 1px solid;
    }
  }
}

/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 900px) {
  $title-b: 1.1rem;
  $title: 1rem;
  $txt: 0.9rem;
  $txt-line: 0.9rem/1.2rem Arial;

  .case-box .more-box {
    font-size: $txt;
    width: 65%;
  }

  .title-box {
    height: auto;
    margin-top: 9vh;
    margin-bottom: 8vh;

    b {
      font-size: 1.2rem;
    }

    .txt-box {
      width: 90%;
      margin: auto;
      font: 0.9rem/1.3rem Arial;
      margin-top: 1vh;
    }
  }

  .serve-box {
    flex-direction: column;

    li {
      width: 90%;
      margin: 4vh auto;
      justify-content: flex-start;

      img {
        width: 45px;
        height: 45px;
        margin-right: 4%;
      }
    }

    .right-box {
      width: 80%;

      b {
        font-size: 1rem;
      }

      .txt {
        font: 0.9rem/1.3rem Arial;
        margin-top: 1.1vh;
      }
    }
  }

  .trait-box {
    width: 100%;

    .overall-box {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
    }

    .left-box {
      display: none;
    }

    .phon-left-box {
      display: inline;
      width: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;

      img {
        width: 140%;
      }
    }

    .right-box {
      width: 90%;
      margin: auto;
      height: auto;

      ul {
        width: 100%;
        margin-top: 3vh;
        margin-bottom: 9vh;
      }

      li {
        font: $txt-line;
        margin-bottom: 2vh;
      }
    }

    b {
      font-size: $title-b;
      display: block;
      width: 100%;
      text-align: left;
    }

    .txt {
      font-size: $txt;
      margin-top: 0.8vh;
    }
  }

  .product-box {
    .content-box {
      flex-direction: column;
      background-size: 20%;

      img {
        width: 100%;
        margin-top: 4vh;
      }
    }
  }

  .case-box .title-box {
    margin-bottom: 5vh;
  }

  .case-box ul {
    flex-direction: column;

    li {
      width: 100%;
      height: auto;
      padding: 3.5vh 0;
      margin: 0 1% 0.5vh;
    }

    li .content-box {
      i {
        font-size: $title-b;
      }

      b {
        font-size: $title;
      }

      .content-txt {
        font: $txt-line;
      }
    }

    li:nth-of-type(1) {
      background-size: auto 10vh;
    }

    li:nth-of-type(2) {
      background-size: auto 10vh;
    }

    li:nth-of-type(3) {
      background-size: auto 10vh;
    }

    li:nth-of-type(4) {
      background-size: auto 10vh;
    }

    li:nth-of-type(5) {
      background-size: auto 10vh;
    }

    li:nth-of-type(6) {
      background-size: auto 10vh;
    }
  }

  .flow-box-content {
    flex-direction: column;

    img {
      width: 90%;
      margin: 2vh auto;
    }

    img.two {
      width: 70%;
      margin: auto;
      margin-top: 5vh;
    }
  }

  .product-box {
    padding-bottom: 8vh;

    .title-box {
      margin-bottom: 4vh;
    }

    .title-button {
      margin: 2vh auto;
      font: 0.9rem/2rem Arial;
    }

    .button {
      margin: 0 10px;
      padding: 0 10px;
    }

    .content-left {
      width: 90%;
      margin: auto;

      b {
        font-size: 1.2rem;
      }

      .txt {
        font: 1rem/1.7rem Arial;
      }

      ul {
        font: 0.9rem/1.8rem Arial;
        margin-top: 1.5vh;
      }

      li {
        text-indent: 18px;
      }
    }
  }

  .new-program-content {
    .button-box {
      margin-bottom: 20px;
      font: 0.9rem/2rem Arial;

      .button {
        margin: 0 10px;
        padding: 0 10px;
        height: 32px;
        line-height: 32px;
      }
    }
  }

  .case-box .ul-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title-box {
      b {
        font-size: 1.15rem;
        margin: 0;
      }
    }

    .div {
      width: 100%;
      background: #f9f9fb;
      padding-bottom: 30px;
      margin-bottom: 50px;
    }

    ul {
      width: 90%;
      margin: auto;
      display: flex;
      flex-direction: column;

      li {
        width: 100%;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 0px 15px rgba(218, 228, 239, 0.5);
        margin: 12px 0;
        min-height: 70px;
        cursor: pointer;
      }

      li:hover {
        width: 100%;
        background: #fff;
        border-radius: 3px;
        box-shadow: 0px 0px 15px rgba(218, 228, 239, 1);
      }

      .li-padding {
        padding: 0;

        .title {
          font-size: 16px;
          font-weight: bold;
          color: #2c3e50;
        }

        .txt {
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
  }

  .product-box .content-left .more[data-v-9ea40744] {
    font-size: 0.8rem;
    width: 40%;
    height: 32px;
    line-height: 32px;
  }
}
</style>
